export default function buildRange(
  start: number,
  end: number,
  increment = 0.25
): string[] {
  const result: string[] = []
  if (start === undefined || end === undefined) return []
  for (let index = Number(start); index <= Number(end); index += increment) {
    result.push(index.toFixed(2))
  }
  return result
}
