export const headers = [
  { name: 'Cod.', field: 'id', sortable: true },
  { name: 'OS', field: 'os', sortable: true },
  { name: 'Cliente', field: 'client.company_name', sortable: true },
  { name: 'Valor', field: 'products_value', sortable: true },
  { name: 'Tipo', field: 'type', sortable: true },
  { name: 'status', field: 'status', sortable: true },
  { name: 'Gerado em', field: 'created_at', sortable: true },
  { name: 'Ações', field: 'actions', sortable: false }
]
