import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import {
  nameActions,
  frameType,
  shipMethods,
  types,
  paymentMethods
} from '../../domain/info'
import { Table } from './styles'
import Form, { Input, Select, Textarea } from '../../../../../components/Form'
import { Date as DatePicker } from '../../../../../components/Form/date'
import {
  convertValueMaskInNumberWithTwoZero,
  genericMaskWithTwoZeroWithPoint
} from '../../../../../utlis/mask'

type OrderData = {
  os?: string
  client_id: number
  products_value?: string
  service_value?: string
  lenses_value?: string
  charged_value?: string
  credit_value?: string
  shipping_method?: string
  shipping_value?: string
  shipping_time?: string
  payment_method?: string
  payment_date?: Date
  installments?: number
  status?: number
  type: string
  profit: string
  note?: string
  user_id?: number
  revenue?: any
}

type Client = {
  id: number
  company_name: string
}

type Product = {
  id: number
  product_category_id: string
  side: string
  cylindrical?: string
  spherical?: string
  addition?: string
  price?: string
  stock: number
}

type Category = {
  id: number
  parent_id: number
  name: string
  price: string
  spherical_start?: number
  spherical_end?: number
  cylindrical_start?: number
  cylindrical_end?: number
  addition_start?: number
  addition_end?: number
}

type SaleTablePrice = {
  product_category_id: number
  table_id: number
  unit_price: string
  wholesale_price: string
  user_id: number
}

type OrderProduct = {
  family: string
  group: string
  cylindrical?: string
  spherical?: string
  addition?: string
  product_id: number
  initial_price: string
  single_discount?: string
  total_discount?: string
  charged_value: string
  cashback_value?: string
  taxes?: string
  quantity: number
  wrap?: boolean
  released?: boolean
  cfop?: number
  stock: number
}

type TypesFormProps = {
  initialValues?: OrderData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

export const FormOrders = ({
  typeForm,
  initialValues
}: TypesFormProps): JSX.Element => {
  const { client_id } = useParams<{ client_id: string }>()
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] = useState<OrderData>()
  const [clients, setClients] = useState<Client[]>([])
  const [client, setClient] = useState<number>()
  const [products, setProducts] = useState<Product[]>([])
  const [orderProducts, setOrderProducts] = useState<OrderProduct[]>([])
  const [categories, setCategories] = useState<Category[]>([])
  const [families, setFamilies] = useState<Category[]>([])
  const [family, setFamily] = useState<number>()
  const [groups, setGroups] = useState<Category[]>([])
  const [group, setGroup] = useState<Category>()
  const [saleTablePrice, setTable] = useState<SaleTablePrice>()
  const [shippingTime, setShippingTime] = useState<Date>()
  const [total, setTotal] = useState<number>()
  const { activeLoading, disableLoading } = useLoading()

  useEffect(() => {
    setClient(Number(client_id))
  }, [client_id])

  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        ...initialValues,
        revenue: initialValues.revenue
          ? JSON.parse(initialValues.revenue)
          : undefined
      })
    }
  }, [initialValues])

  useEffect(() => {
    ;(async () => {
      const { data } = await api.get('commercial/clients')
      const families = await api.get('warehouse/productCategories')
      setClients(data)
      setCategories(families.data)
    })()
  }, [])

  useEffect(() => {
    if (!categories || !categories.length) return
    const families = categories.filter(c => !c.parent_id)
    setFamilies(families)
  }, [categories])

  useEffect(() => {
    if (!family) setGroups(undefined)
    const groups = categories.filter(c => c.parent_id === family)
    setGroups(groups)
  }, [family, categories])

  const onSubmit = async (data: OrderData) => {
    if (!client) {
      addToast({
        type: 'info',
        title: 'Selecione um cliente',
        description: 'Selecione um cliente'
      })
      return
    }
    if (!orderProducts.length) {
      addToast({
        type: 'info',
        title: 'Selecione um produto pelo menos',
        description:
          'Faça uma busca filtrando os produtos, e escolha pelo menos um'
      })
      return
    }
    try {
      if (typeForm === 'create') {
        try {
          const dataCreate = {
            client_id: client,
            ...data
          }
          activeLoading()
          await api.post(apiCreate(), dataCreate)
          disableLoading()
          updateDataTable()
          addToast({
            type: 'success',
            title: 'Registro criado',
            description: 'Registro criado com sucesso'
          })
          history.push(nameActions.read.to)
        } catch (error) {
          addToast({
            type: 'error',
            title: 'Erro ao adicionar o registro',
            description:
              'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
          })
          disableLoading()
          updateDataTable()
        }
      } else {
        const dataUpdate = {
          client_id: client,
          ...data
        }
        const id = initialValues?.idUpdate

        try {
          activeLoading()
          await api.put(apiUpdate(String(id)), dataUpdate)
          updateDataTable()
          disableLoading()
          history.push(nameActions.read.to)
          addToast({
            type: 'success',
            title: 'Registro atualizado',
            description: 'Registro alterado com sucesso'
          })
        } catch (error) {
          history.push(nameActions.read.to)
          addToast({
            type: 'error',
            title: 'Erro ao atualizar o registro',
            description:
              'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
          })
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
      }
    }
  }

  const searchProduct = async (fields: any) => {
    let product: any = {
      bars_code: fields.bars_code,
      product_category_id: fields.group || undefined
    }
    if (!product.bars_code) {
      product = {
        product_category_id: fields.group || undefined,
        cylindrical: fields.cylindrical || undefined,
        spherical: fields.spherical || undefined,
        addition: fields.addition || undefined
      }
    }
    const { data } = await api.get('warehouse/products/search', {
      params: {
        product,
        client_id: fields.client_id
      }
    })
    setProducts(data.products)
    setTable(data.table)
  }

  function buildRange(start: number, end: number, increment: number): string[] {
    const result: string[] = []
    for (let index = start; index <= end; index += increment) {
      result.push(index.toFixed(2))
    }
    return result
  }

  function addProduct(product: OrderProduct) {
    setOrderProducts(oldValue => {
      const exist = oldValue.find(ov => ov.product_id === product.product_id)
      if (exist) {
        addToast({
          type: 'error',
          title: 'Produto já adicionado',
          description:
            'Produto já selecionado, caso queria, aumente a quantidade do mesmo.'
        })
      }
      const products = exist ? oldValue : [...oldValue, { ...product }]
      return products
    })
  }

  function removeProduct(product: OrderProduct) {
    setOrderProducts(oldValue => {
      const copy = JSON.parse(JSON.stringify(oldValue))
      const index = oldValue.indexOf(product)
      if (index !== -1) {
        copy.splice(index, 1)
      }
      return copy
    })
  }

  useEffect(() => {
    setTotal(
      orderProducts
        .map(a => convertValueMaskInNumberWithTwoZero(a.charged_value))
        .reduce((previous, current) => {
          return current + previous
        }, 0)
    )
  }, [orderProducts])
  return (
    <>
      <Form onSubmit={searchProduct}>
        <h2>Cliente</h2>
        <div className="row">
          <Select
            label="Cliente"
            name="client_id"
            className=" col-md-3"
            value={client || ''}
            onChange={e => setClient(Number(e.target.value))}
            options={clients.map(client => ({
              name: client.company_name,
              value: client.id
            }))}
            blank
            rules={{ required: true }}
            errors={{ client_id: !client }}
            controlled
          />
        </div>
        <div className="separator my-5" />
        <h2>Buscar Produto</h2>
        <div className="row">
          <Input label="Cód. Barra" name="bars_code" className="col-md-1" />
          <Select
            label="Familia"
            name="family"
            className="col-md-2"
            onChange={e => setFamily(Number(e.target.value))}
            options={families.map(family => ({
              name: family.name,
              value: family.id
            }))}
            rules={{ required: true }}
            blank
            defaultValue={''}
          />
          <Select
            label="Grupo"
            name="group"
            className="col-md-2"
            onChange={e => {
              const group = groups.find(
                g => Number(g.id) === Number(e.target.value)
              )
              console.log('group', group)
              if (group) {
                setGroup(group)
              }
            }}
            options={groups.map(group => ({
              name: group.name,
              value: group.id
            }))}
            rules={{ required: true }}
            blank
            defaultValue={''}
            controlled
          />
          <Select
            label="Esférico"
            name="spherical"
            className="col-md-2"
            options={
              group
                ? buildRange(
                    Number(group.spherical_start),
                    Number(group.spherical_end),
                    0.25
                  ).map(i => ({
                    name: i,
                    value: i
                  }))
                : []
            }
            blank
            defaultValue={''}
          />
          <Select
            label="Cilindrico"
            name="cylindrical"
            className="col-md-2"
            options={
              group
                ? buildRange(
                    Number(group.cylindrical_start),
                    Number(group.cylindrical_end),
                    0.25
                  ).map(i => ({
                    name: i,
                    value: i
                  }))
                : []
            }
            blank
            defaultValue={''}
          />
          <Select
            label="Adição"
            name="addition"
            className="col-md-2"
            options={
              group?.addition_start
                ? buildRange(
                    Number(group.addition_start),
                    Number(group.addition_end),
                    0.25
                  ).map(i => ({
                    name: i,
                    value: i
                  }))
                : []
            }
            blank
            defaultValue={''}
          />
          <div className="col-md-1">
            <button type="submit" className="btn btn-info mt-13">
              Filtrar
            </button>
          </div>
        </div>
      </Form>
      {products?.length ? (
        <>
          <div className="separator my-5" />
          <h2>Resultado da busca</h2>
          <table className="table table-striped table-hover fs-6">
            <thead className="fw-bold">
              <tr>
                <th scope="col">Lado</th>
                <th scope="col">Esférico</th>
                <th scope="col">Cilíndrico</th>
                <th scope="col">Adição</th>
                <th scope="col">Preço custo</th>
                <th scope="col">Preço normal</th>
                <th scope="col">Preço atacado</th>
                <th scope="col">Estoque</th>
                <th scope="col">Ações</th>
              </tr>
            </thead>
            <tbody>
              {products?.map(product => (
                <tr key={product.id}>
                  <td>{product.side}</td>
                  <td>{product.spherical}</td>
                  <td>{product.cylindrical}</td>
                  <td>{product.addition}</td>
                  <td>{genericMaskWithTwoZeroWithPoint(group.price)}</td>
                  <td>
                    {genericMaskWithTwoZeroWithPoint(
                      saleTablePrice?.unit_price
                    )}
                  </td>
                  <td>
                    {genericMaskWithTwoZeroWithPoint(
                      saleTablePrice?.wholesale_price
                    )}
                  </td>
                  <td>{product.stock}</td>
                  <td>
                    <button
                      className="btn btn-solid"
                      onClick={() =>
                        addProduct({
                          family: family.toString(),
                          group: group.name,
                          spherical: product.spherical,
                          cylindrical: product.cylindrical,
                          addition: product.addition,
                          initial_price: saleTablePrice?.unit_price,
                          charged_value: saleTablePrice?.unit_price,
                          quantity: 1,
                          product_id: product.id,
                          stock: product.stock
                        })
                      }
                    >
                      <span className="fa fa-plus"></span>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <></>
      )}
      <Form onSubmit={onSubmit} defaultValues={defaultValues}>
        {orderProducts?.length ? (
          <>
            <div className="separator my-5" />
            <h2>Produtos Selecionados</h2>
            <table className="table table-striped table-hover fs-6">
              <thead className="fw-bold">
                <tr>
                  <th scope="col">Família</th>
                  <th scope="col">Grupo</th>
                  <th scope="col">Esférico</th>
                  <th scope="col">Cilíndrico</th>
                  <th scope="col">Adição</th>
                  <th scope="col" style={{ width: '150px' }}>
                    Preço
                  </th>
                  <th scope="col">Estoque</th>
                  <th scope="col" style={{ width: '100px' }}>
                    Quant.
                  </th>
                  <th scope="col" style={{ width: '150px' }}>
                    Sub total
                  </th>
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {orderProducts?.map((product, i) => (
                  <tr key={product.product_id}>
                    <td>{product.family}</td>
                    <td>{product.group}</td>
                    <td>{product.spherical}</td>
                    <td>{product.cylindrical}</td>
                    <td>{product.addition}</td>
                    <td>
                      <Input
                        className="m-0"
                        name={`products.${i}.initial_price`}
                        value={genericMaskWithTwoZeroWithPoint(
                          product.initial_price
                        )}
                        onChange={e => {
                          setOrderProducts(oldValue => {
                            const copy = [...oldValue]
                            const that = copy.find(
                              c => c.product_id === product.product_id
                            )
                            that.initial_price =
                              genericMaskWithTwoZeroWithPoint(e.target.value)
                            return copy
                          })
                        }}
                        controlled
                      />
                      <Input
                        className="m-0"
                        name={`products.${i}.product_id`}
                        type="hidden"
                        value={product.product_id}
                        controlled
                      />
                    </td>
                    <td>{product.stock}</td>
                    <td>
                      <Input
                        className="m-0"
                        name={`products.${i}.quantity`}
                        value={product.quantity}
                        onChange={e => {
                          let value = Number(e.target.value)
                          const over = value > product.stock
                          if (!e.target.value) {
                            value = 1
                          }
                          if (over) {
                            value = 1
                            addToast({
                              title: 'Quantidade  maior que o stock!',
                              type: 'error'
                            })
                          }
                          setOrderProducts(oldValue => {
                            const copy = JSON.parse(JSON.stringify(oldValue))
                            const that = copy.find(
                              (c: any) => c.product_id === product.product_id
                            )
                            that.quantity = over ? 1 : e.target.value
                            that.charged_value =
                              genericMaskWithTwoZeroWithPoint(
                                String(
                                  value *
                                    Number(that.initial_price.replace(',', ''))
                                )
                              )
                            return copy
                          })
                        }}
                        onKeyPress={event => {
                          const regex = /^[0-9.]+$/
                          if (!regex.test(event.key)) {
                            event.preventDefault()
                          }
                        }}
                        controlled
                      />
                    </td>
                    <td>
                      <Input
                        className="m-0"
                        name={`products.${i}.charged_value`}
                        value={genericMaskWithTwoZeroWithPoint(
                          product.charged_value
                        )}
                        fullControlled
                        readOnly
                      />
                    </td>
                    <td>
                      <a className="btn" onClick={() => removeProduct(product)}>
                        <span className="fa fa-close"></span>
                      </a>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <h2>Total: R$ {` ${total.toFixed(2)}`}</h2>
                  </td>
                  <td>
                    <Input name="profit" type="hidden" value="N" controlled />
                    <Input
                      name="products_value"
                      type="hidden"
                      value={String(total)}
                      controlled
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        ) : (
          <></>
        )}
        <div className="separator my-5" />
        <h2>Dados da receita</h2>
        <Table className="table table-bordered fs-6 fw-bold">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col ">Esférico</th>
              <th scope="col">Cilindrico</th>
              <th scope="col">Eixo</th>
              <th scope="col">DNP</th>
              <th scope="col">AP</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                <span>OD</span>
              </th>
              <td>
                <Input name="revenue.od.spherical" />
              </td>
              <td>
                <Input name="revenue.od.cylindrical" />
              </td>
              <td>
                <Input name="revenue.od.axe" />
              </td>
              <td>
                <Input name="revenue.od.dnp" />
              </td>
              <td>
                <Input name="revenue.od.ap" />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <span>OE</span>
              </th>
              <td>
                <Input name="revenue.oe.spherical" />
              </td>
              <td>
                <Input name="revenue.oe.cylindrical" />
              </td>
              <td>
                <Input name="revenue.oe.axe" />
              </td>
              <td>
                <Input name="revenue.oe.dnp" />
              </td>
              <td>
                <Input name="revenue.oe.ap" />
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="row">
          <Input
            label="Adição"
            name="revenue.addition"
            className="col-md-2"
            price
          />
          <Select
            label="Tipo Armação"
            name="revenue.frameType"
            className="col-md-2"
            options={frameType}
            blank
            defaultValue={''}
          />
          <Input
            label="Armação - ponte"
            name="revenue.frameBridge"
            className="col-md-2"
            price
          />
          <Input
            label="Armação - horizontal"
            name="revenue.frameHorizontal"
            className="col-md-2"
            price
          />
          <Input
            label="Armação - diagonal maior"
            name="revenue.frameDiagonal"
            className="col-md-2"
            price
          />
          <Input
            label="Armação - vertical"
            name="revenue.frameVertical"
            className="col-md-2"
            price
          />
        </div>
        <div className="separator my-5" />
        <h2>Dados do Pedido</h2>
        <div className="row">
          <Input
            label="O.S."
            name="os"
            className=" col-md-3"
            rules={{ required: true }}
          />
          <Select
            label="Forma de entrega"
            name="shipping_method"
            className="col-md-3"
            options={shipMethods}
            blank
            defaultValue={''}
            rules={{ required: true }}
          />
          <Select
            label="Tipo"
            name="type"
            className="col-md-3"
            options={types}
            blank
            defaultValue={''}
            rules={{ required: true }}
          />
        </div>
        <div className="row">
          <Select
            label="Forma de pagamento"
            name="payment_method"
            className="col-md-3"
            options={paymentMethods}
            blank
            defaultValue={''}
            rules={{ required: true }}
          />
          <DatePicker
            label="Data de vencimento da fatura"
            name="shipping_time"
            className="col-md-3"
            selected={shippingTime}
            onChange={date => setShippingTime(date)}
            minDate={new Date()}
            controlled
            rules={{ required: true }}
          />
        </div>
        <div className="row">
          <Textarea
            label="Observações"
            name="note"
            className="col-md-12"
            rows={7}
          />
        </div>
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button type="submit" className="btn btn-primary">
            Salvar
          </button>
        </div>
      </Form>
    </>
  )
}
