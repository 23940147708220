import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'

import Form, { Input, Select, Textarea } from '../../../../../components/Form'
import Button from '../../../../../components/Button'
import { FormContent } from './styles'

type SaleTableData = {
  name: string
  description: string
  active: number | boolean
}

type TypesFormProps = {
  initialValues?: SaleTableData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

export const FormSaleTable = ({
  typeForm,
  initialValues
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] = useState<SaleTableData>()
  const { activeLoading, disableLoading } = useLoading()

  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        name: initialValues.name,
        description: initialValues.description,
        active: initialValues.active ? 1 : 0
      })
    }
  }, [initialValues])

  const onSubmit = async (data: SaleTableData) => {
    try {
      if (typeForm === 'create') {
        try {
          const dataCreate = {
            ...data,
            active: Number(data.active)
          }
          activeLoading()
          await api.post(apiCreate(), dataCreate)
          disableLoading()
          updateDataTable()
          addToast({
            type: 'success',
            title: 'Registro criado',
            description: 'Registro criado com sucesso'
          })
          history.push(nameActions.read.to)
        } catch (error) {
          addToast({
            type: 'error',
            title: 'Erro ao adicionar o registro',
            description:
              'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
          })
          disableLoading()
          updateDataTable()
        }
      } else {
        const dataUpdate = {
          ...data,
          active: Number(data.active)
        }
        const id = initialValues?.idUpdate

        try {
          activeLoading()
          await api.put(apiUpdate(String(id)), dataUpdate)
          updateDataTable()
          disableLoading()
          history.push(nameActions.read.to)
          addToast({
            type: 'success',
            title: 'Registro atualizado',
            description: 'Registro alterado com sucesso'
          })
        } catch (error) {
          history.push(nameActions.read.to)
          addToast({
            type: 'error',
            title: 'Erro ao atualizar o registro',
            description:
              'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
          })
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
      }
    }
  }

  return (
    <div className="card mb-5 mb-xl-10">
      <Form onSubmit={onSubmit} defaultValues={defaultValues}>
        <div>
          <FormContent className="form-body">
            <div className="row">
              <Input
                label="Nome"
                name="name"
                className=" col-md-3"
                rules={{ required: true }}
              />

              <Select
                label="Ativo"
                name="active"
                className=" col-md-3"
                options={[
                  { name: 'Sim', value: 1 },
                  { name: 'Não', value: 0 }
                ]}
                defaultValue={1}
                rules={{ required: true }}
              />
            </div>
            <div className="row">
              <Textarea
                label="Descrição"
                name="description"
                className=" col-md-12"
                style={{ minHeight: 120 }}
                defaultValue=""
                rules={{ required: true }}
              />
            </div>
          </FormContent>
        </div>
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <Button type="submit" className="btn btn-primary">
            Salvar
          </Button>
        </div>
      </Form>
    </div>
  )
}
