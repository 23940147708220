import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'

import Form, { Input, Select, Textarea } from '../../../../../components/Form'
import Button from '../../../../../components/Button'
import { FormContent } from './styles'
import { cnpjMask, phoneMask, zipCodeMask } from '../../../../../utlis/mask'
import jsonp from 'jsonp'
import { handleErrorMessage } from 'utlis/handleErros'

type ProvidersData = {
  company_social_name: string
  company_name: string
  cnpj?: string
  phone: string
  mobile?: string
  email?: string
  street?: string
  number?: string
  complement?: string
  district?: string
  zip_code?: string
  city?: string
  state?: string
  ibge?: number
  note?: string
  active: string
}

type TypesFormProps = {
  initialValues?: ProvidersData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

type Company = {
  company_social_name: string
  company_name: string
  cnpj: string
  number: string
  complement: string
  cnpjSearch: string
}
type Address = {
  street: string
  district: string
  city: string
  state: string
  ibge: string
}

export const FormProvider = ({
  typeForm,
  initialValues
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] = useState<ProvidersData>()
  const { activeLoading, disableLoading } = useLoading()
  const [company, setCompany] = useState<Company>()
  const [address, setAddress] = useState<Address>()
  const [cnpj, setCnpj] = useState<string>()
  const [zipCode, setZipCode] = useState<string>()
  const [phone, setPhone] = useState<string>()
  const [mobile, setMobile] = useState<string>()

  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        ...initialValues,
        cnpj: cnpjMask(initialValues?.cnpj || ''),
        phone: phoneMask(initialValues?.phone || ''),
        mobile: phoneMask(initialValues?.mobile || '')
      })
    }
  }, [initialValues])

  const onSubmit = async (data: ProvidersData) => {
    data.cnpj = cnpjMask(data.cnpj)
    try {
      if (typeForm === 'create') {
        try {
          const dataCreate = {
            ...data,
            phone: data.phone?.replace(' ', ''),
            mobile: data.mobile?.replace(' ', ''),
            ibge: address?.ibge,
            note: data.note?.length ? data.note : null,
            cnpjSearch: company?.cnpjSearch
          }
          activeLoading()
          await api.post(apiCreate(), dataCreate)
          disableLoading()
          updateDataTable()
          addToast({
            type: 'success',
            title: 'Registro criado',
            description: 'Registro criado com sucesso'
          })
          history.push(nameActions.read.to)
        } catch (error: any) {
          addToast({
            ...handleErrorMessage(error)
          })
          disableLoading()
          updateDataTable()
        }
      } else {
        const dataUpdate = {
          ...data,
          phone: data.phone?.replace(' ', ''),
          mobile: data.mobile?.replace(' ', ''),
          ibge: address?.ibge,
          note: data.note?.length ? data.note : null,
          cnpjSearch: company?.cnpjSearch
        }
        const id = initialValues?.idUpdate

        try {
          activeLoading()
          await api.put(apiUpdate(String(id)), dataUpdate)
          updateDataTable()
          disableLoading()
          history.push(nameActions.read.to)
          addToast({
            type: 'success',
            title: 'Registro atualizado',
            description: 'Registro alterado com sucesso'
          })
        } catch (error: any) {
          history.push(nameActions.read.to)
          addToast({
            ...handleErrorMessage(error)
          })
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
      }
    }
  }

  const getDataCep = useCallback(
    async (zipCodeData: string) => {
      setZipCode(zipCodeMask(zipCodeData))
      const zipCodeSearch = zipCodeData?.replaceAll(/[.\-/]/g, '')
      if (zipCodeSearch.length === 8) {
        activeLoading()
        try {
          const response = await api.get(
            `https://viacep.com.br/ws/${zipCodeSearch}/json`,
            {
              timeout: 10000
            }
          )
          const { bairro, ibge, localidade, logradouro, uf } = response.data
          setAddress({
            city: localidade,
            district: bairro,
            state: uf,
            street: logradouro,
            ibge
          })
        } catch (error) {
          addToast({
            title: 'Erro ao carregar os dados para o cep',
            description:
              'Houve um erro ao carregar os dados para o cep, digite os dados manualmente!',
            type: 'error'
          })
        }
        disableLoading()
      }
    },
    [activeLoading, addToast, disableLoading]
  )
  const getDataCnpj = useCallback(
    async (cnpjData: string) => {
      setCnpj(cnpjMask(cnpjData))
      const cnpjSearch = cnpjData?.replaceAll(/[.\-/]/g, '')
      if (cnpjSearch.length === 14) {
        activeLoading()
        jsonp(
          `https://www.receitaws.com.br/v1/cnpj/${cnpjSearch}`,
          {
            timeout: 10000
          },
          (error: any, data: any) => {
            if (error) {
              addToast({
                title: 'Erro ao carregar os dados para o cnpj',
                description:
                  'Houve um erro ao carregar os dados para o cnpj, digite os dados manualmente!',
                type: 'error'
              })
            } else {
              setCompany({
                cnpjSearch: data,
                company_social_name: data.fantasia,
                company_name: data.nome,
                cnpj: cnpjSearch,
                complement: data.complemento,
                number: data.numero
              })
              getDataCep(data.cep)
            }
            disableLoading()
          }
        )
      }
    },
    [activeLoading, addToast, disableLoading, getDataCep]
  )

  return (
    <div className="card mb-5 mb-xl-10">
      <Form
        onSubmit={onSubmit}
        defaultValues={{
          ...defaultValues,
          ...address,
          ...company
        }}
      >
        <div>
          <FormContent className="form-body">
            <div className="row mb-5">
              <Input
                name="cnpj"
                className=" col-md-2"
                label="CNPJ"
                rules={{ required: true }}
                readOnly={typeForm === 'update'}
                value={cnpj}
                onChange={event => getDataCnpj(event.target.value)}
              />
              <Input
                name="company_name"
                className=" col-md-5"
                label="Razão Social"
                rules={{ required: true }}
                onChange={event =>
                  setCompany({
                    ...company,
                    company_name: event.target.value
                  })
                }
              />
              <Input
                name="company_social_name"
                className=" col-md-5"
                label="Nome Fantasia"
                rules={{ required: true, position: 'left' }}
                onChange={event =>
                  setCompany({
                    ...company,
                    company_social_name: event.target.value
                  })
                }
              />
            </div>
            <div className="separator my-5" />
            <div className="row mb-5">
              <Input
                name="zip_code"
                className=" col-md-3"
                label="CEP"
                rules={{ required: true }}
                value={zipCode}
                onChange={event => getDataCep(event.target.value)}
                maxLength={9}
              />
              <Input
                name="street"
                className=" col-md-6"
                label="Logradouro"
                rules={{ required: true }}
              />
              <Input
                name="number"
                className=" col-md-3"
                label="Número"
                rules={{ required: true }}
              />
            </div>
            <div className="row mb-5">
              <Input
                name="complement"
                className=" col-md-3"
                label="Complemento"
                rules={{ required: true }}
              />
              <Input
                name="district"
                className=" col-md-3"
                label="Bairro"
                rules={{ required: true }}
              />
              <Input
                name="city"
                className=" col-md-3"
                label="Cidade"
                rules={{ required: true }}
              />
              <Input
                name="state"
                className=" col-md-3"
                label="UF"
                rules={{
                  required: true,
                  maxLength: { value: 2, message: 'No máximo 2 caracteres' }
                }}
              />
            </div>
            <div className="separator my-5" />
            <div className="row mb-5">
              <Input
                name="phone"
                className=" col-md-3"
                label="Telefone"
                value={phone}
                onChange={event => setPhone(phoneMask(event.target.value))}
                maxLength={15}
              />
              <Input
                name="mobile"
                className=" col-md-3"
                label="Celular"
                value={mobile}
                onChange={event => setMobile(phoneMask(event.target.value))}
                maxLength={15}
              />
              <Input name="email" className=" col-md-3" label="E-mail" />
              <Select
                className=" col-md-3"
                name="active"
                label="Ativo"
                options={[
                  {
                    name: 'Sim',
                    value: 'S'
                  },
                  {
                    name: 'Não',
                    value: 'N'
                  }
                ]}
                blank
                defaultValue="S"
                rules={{ required: true }}
              />
            </div>

            <div className="separator my-5" />
            <div className="row">
              <Textarea
                name="note"
                className=" col-md-12"
                label="Observações"
                style={{ minHeight: 120 }}
                defaultValue=""
              />
            </div>
          </FormContent>
        </div>
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <Button type="submit" className="btn btn-primary">
            Salvar
          </Button>
        </div>
      </Form>
    </div>
  )
}
