export function numericMask(value: string): string {
  if (!value) {
    let newvalue = value?.replace(/\D/g, '')
    newvalue = newvalue?.replace(/(\d{2})$/, ',$1')
    newvalue = newvalue?.replace(/(\d+)(\d{3},\d{2})$/g, '$1.$2')
    const qtdLoop = (newvalue?.length - 3) / 3
    let count = 0
    while (qtdLoop > count) {
      count++
      newvalue = newvalue?.replace(/(\d+)(\d{3}.*)/, '$1.$2')
    }
    newvalue = newvalue?.replace(/^(0)(\d)/g, '$2')
    return newvalue
  }
  return value
}

export const convertValueWithMaskInNumber = (value: string): string => {
  return value
}

export function weightMask(value: string): string {
  let newValue = value
  const integer = newValue.split('.')[0]

  newValue = newValue.replace(/\D/g, '')

  newValue = newValue.replace(/^[0]+/, '')

  if (newValue.length <= 3 || !integer) {
    if (newValue.length === 1) newValue = '0,00' + newValue

    if (newValue.length === 2) newValue = '0,0' + newValue

    if (newValue.length === 3) newValue = '0,' + newValue
  } else {
    newValue = newValue.replace(/^(\d{1,})(\d{3})$/, '$1,$2')
  }

  return newValue
}

export function genericMaskWithTwoZero(value: string): string {
  let newValue = value
  const integer = newValue?.split('.')[0]

  newValue = newValue?.replace(/\D/g, '')

  newValue = newValue?.replace(/^[0]+/, '')

  if (newValue?.length <= 2 || !integer) {
    if (newValue?.length === 1) newValue = '0,0' + newValue

    if (newValue?.length === 2) newValue = '0,' + newValue
  } else {
    newValue = newValue?.replace(/^(\d{1,})(\d{2})$/, '$1,$2')
  }

  return newValue
}
export function genericMaskWithTwoZeroWithPoint(value: string): string {
  let newValue = value
  // const integer = newValue?.split('.')[0]

  newValue = newValue?.replace(/\D/g, '')

  newValue = newValue?.replace(/^[0]+/, '')

  if (newValue?.length <= 2) {
    if (newValue?.length === 1) newValue = '0,0' + newValue

    if (newValue?.length === 2) newValue = '0,' + newValue
  } else {
    newValue = newValue?.replace(/(\d)(\d{2})$/, '$1,$2')
    newValue = newValue?.replace(/(?=(\d{3})+(\D))\B/g, '.')
  }

  return newValue
}

export function convertValueMaskInNumberWithTwoZero(value: string): number {
  let newValue = value.replace('.', '').replace(',', '')
  const integer = newValue.split('.')[0]

  newValue = newValue.replace(/\D/g, '')

  newValue = newValue.replace(/^[0]+/, '')

  if (newValue.length <= 2 || !integer) {
    if (newValue.length === 1) newValue = '0,0' + newValue

    if (newValue.length === 2) newValue = '0,' + newValue
  } else {
    newValue = newValue.replace(/^(\d{1,})(\d{2})$/, '$1,$2')
  }
  newValue = newValue.replace(',', '.')

  return parseFloat(newValue)
}

export function convertValueMaskInNumber(value: string): number {
  let newValue = value
  const integer = newValue.split('.')[0]

  newValue = newValue.replace(/\D/g, '')

  newValue = newValue.replace(/^[0]+/, '')

  if (newValue.length <= 3 || !integer) {
    if (newValue.length === 1) newValue = '0,00' + newValue

    if (newValue.length === 2) newValue = '0,0' + newValue

    if (newValue.length === 3) newValue = '0,' + newValue
  } else {
    newValue = newValue.replace(/^(\d{1,})(\d{3})$/, '$1,$2')
  }
  newValue = newValue.replace(',', '.')

  return parseFloat(newValue)
}

export function phoneMask(v: string): string {
  if (!v) return ''
  v = v.replace(/[^\d]/g, '') // Remove tudo o que não é dígito
  v = v.replace(/^(\d{2})(\d)/g, '($1) $2') // Coloca parênteses em volta dos dois primeiros dígitos
  v = v.replace(/(\d)(\d{4})$/, '$1-$2') // Coloca hífen entre o quarto e o quinto dígitos
  return v
}

export function zipCodeMask(v: string): string {
  if (!v) return ''
  v = v.replace(/[^\d]/g, '') // Remove tudo o que não é dígito
  v = v.replace(/^(\d{5})(\d)/g, '$1-$2') // Coloca parênteses em volta dos dois primeiros dígitos
  return v
}

export const cnpjMask = (v: string) => {
  return v
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}
