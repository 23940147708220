import { useCallback, useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select, Textarea } from '../../../../../components/Form'
import { Datalist } from '../../../../../components/Datalist'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { FormContainer } from './styles'
import { Date as DatePicker } from '../../../../../components/Form/date'
import { genericMaskWithTwoZeroWithPoint } from '../../../../../utlis/mask'
import { useAuth } from '../../../../../hooks/auth'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

interface ExpenseData {
  provider_id?: number
  provider?: {
    company_social_name: string
  }
  financial_moviment_type_id: number
  category_id: number
  sub_category_id: number
  description: string
  due_date?: Date
  value: string
  fees_fines_value?: string
  nf_number?: number
  finished: string
  payment_method?: string
  recurrence?: number
  operation_type: string
  generated_user_id?: number
  downloaded_at?: Date
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: ExpenseData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

type Provider = {
  id: number
  company_social_name: string
}

type FinancialCategory = {
  id: number
  type: string
  name: string
}

export const FormExpense = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { clientApplication } = useAuth()
  const { updateDataTable } = useUpdateDataTable()

  const [defaultValues, setDefaultValues] = useState<ExpenseData>()
  const [recurrence, setRecurrence] = useState(false)
  const [providers, setProviders] = useState([])
  const [financialCategories, setFinancialCategories] = useState<any[]>([])
  const [financialSubCategories, setFinancialSubCategories] = useState<any[]>(
    []
  )
  const [startDate, setStartDate] = useState<Date>()
  const [value, setValue] = useState('')
  const [feesFinesValue, setFeesFinesValue] = useState('0,00')
  const [providerId, setProviderId] = useState<number>()
  const [paymentGateways] = useState<any[]>([
    { value: 'F', name: 'Fatura' },
    { value: 'D', name: 'Dinheiro' },
    { value: 'C', name: 'Cheque' },
    { value: 'B', name: 'Boleto' },
    { value: 'T', name: 'Transferência Bancária' }
  ])
  const { activeLoading, disableLoading } = useLoading()

  async function getFinancialGroups() {
    try {
      const response = await api.get('/financial/categories/list')
      const filterCategories = response.data.filter(
        (category: FinancialCategory) => category.type === 'D'
      )
      setFinancialCategories(
        filterCategories?.map(
          (category: FinancialCategory) =>
            category && {
              value: category.id,
              name: category.name,
              ...category
            }
        )
      )
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao carregar os grupos de movimentação',
        description:
          'Ocorreu um erro ao carregar os grupos de movimentação, por favor, tente novamente.'
      })
    }
  }
  async function getProviders() {
    try {
      const response = await api.get('/commercial/providers')
      setProviders(
        response.data?.map(
          (provider: Provider) =>
            provider && {
              id: provider.id,
              value: provider.id,
              name: provider.company_social_name
            }
        )
      )
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao carregar os fornecedores',
        description:
          'Ocorreu um erro ao carregar os fornecedores, por favor, tente novamente.'
      })
    }
  }
  useEffect(() => {
    getFinancialGroups()
    getProviders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFinacialCategory = useCallback(
    async (categoryId: string) => {
      const findCategory = financialCategories.find(
        category => category.id === Number(categoryId)
      )
      if (findCategory) {
        setFinancialSubCategories(
          findCategory.childCategories.map(
            (subCategory: any) =>
              subCategory && {
                name: subCategory.name,
                value: subCategory.id
              }
          )
        )
      }
    },
    [financialCategories]
  )

  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        ...initialValues,
        category_id: initialValues.category_id,
        fees_fines_value: Number(
          initialValues?.fees_fines_value
        ).toLocaleString('pt-br', {
          minimumFractionDigits: 2
        })
      })
      handleFinacialCategory(String(initialValues.category_id))
      setValue(genericMaskWithTwoZeroWithPoint(initialValues.value))
      setFeesFinesValue(
        Number(initialValues?.fees_fines_value).toLocaleString('pt-br', {
          minimumFractionDigits: 2
        })
      )
      setProviderId(initialValues.provider_id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  const onSubmitForm = async (data: ExpenseData) => {
    const id = initialValues?.idUpdate
    data.provider_id = providerId ? Number(providerId) : null
    data.recurrence = data.recurrence ? Number(data.recurrence) : null
    data.operation_type = 'D'
    data.generated_user_id = Number(clientApplication.id)
    data.category_id = Number(data.category_id)
    data.sub_category_id = Number(data.sub_category_id)
    data.value = value.replaceAll('.', '').replaceAll(',', '.')
    data.fees_fines_value = feesFinesValue
      .replaceAll('.', '')
      .replaceAll(',', '.')

    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose, idParent } = isOpenInModal
          const dataCreate = {
            ...data,
            parent_id: idParent
          }
          activeLoading()
          try {
            await api.post(apiCreate(), dataCreate)
            handleOnClose()
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            handleOnClose()
            disableLoading()
            updateDataTable()
          }
        } else {
          try {
            const dataCreate = {
              ...data
            }
            activeLoading()
            await api.post(apiCreate(), dataCreate)
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
            history.push(nameActions.read.to)
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            disableLoading()
            updateDataTable()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataUpdate = {
            ...data
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          const dataUpdate = {
            ...data
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            history.push(nameActions.read.to)
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }

  return (
    <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
      <>
        <div className="card mb-5 mb-xl-10">
          {typeForm === 'create' ? (
            <FormContainer className="form">
              <div className="row mb-5">
                <Datalist
                  label="Fornecedor"
                  classname="col-md-6"
                  setValue={(item: any) => setProviderId(item.id)}
                  data={providers}
                />

                <Select
                  className="col-md-3"
                  name="category_id"
                  label="Categoria Financeira"
                  rules={{ required: true }}
                  options={financialCategories}
                  blank
                  defaultValue=""
                  onChange={event => handleFinacialCategory(event.target.value)}
                />
                <Select
                  className="col-md-3"
                  name="sub_category_id"
                  label="Sub Categoria Financeira"
                  rules={{ required: true }}
                  options={financialSubCategories}
                  blank
                  defaultValue=""
                />
              </div>
              <div className="row mb-5">
                <Textarea
                  className="col-md-12"
                  name="description"
                  label="Descrição"
                  rules={{ required: true }}
                  style={{ minHeight: 120 }}
                />
              </div>
              <div className="row mb-5">
                <DatePicker
                  className="col-md-3"
                  name="due_date"
                  label="Data de Vencimento"
                  rules={{ required: true }}
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  minDate={new Date()}
                  controlled
                />
                <Select
                  className="col-md-3"
                  name="recurrence"
                  label="Replicar a despesa"
                  blank
                  defaultValue=""
                  onChange={event =>
                    setRecurrence(!!Number(event.target.value))
                  }
                  options={[
                    {
                      name: 'Sim',
                      value: 1
                    },
                    {
                      name: 'Não',
                      value: 0
                    }
                  ]}
                />
                {recurrence ? (
                  <>
                    <Input
                      className="col-md-3"
                      name="number_recurrence"
                      label="Número de replicações ?"
                      rules={{ required: true }}
                    />
                    <Select
                      className="col-md-3"
                      name="frequency"
                      label="Frequência"
                      rules={{ required: true }}
                      defaultValue=""
                      blank
                      options={[
                        {
                          name: 'Semanalmente',
                          value: 's'
                        },
                        {
                          name: 'Quinzenalmente',
                          value: 'q'
                        },
                        {
                          name: 'Mensalmente',
                          value: 'm'
                        }
                      ]}
                    />
                  </>
                ) : (
                  <>
                    <Input
                      className="col-md-3"
                      name="value"
                      label="Valor"
                      placeholder="0,00"
                      rules={{ required: true }}
                      onChange={event =>
                        setValue(
                          genericMaskWithTwoZeroWithPoint(event.target.value)
                        )
                      }
                      value={value}
                    />
                    <Input
                      className="col-md-3"
                      name="fees_fines_value"
                      label="Juros/Multa"
                      placeholder="0,00"
                      onChange={({ target }) =>
                        setFeesFinesValue(
                          genericMaskWithTwoZeroWithPoint(target.value)
                        )
                      }
                      value={feesFinesValue}
                    />
                  </>
                )}
              </div>
              <div className="row mb-5">
                {recurrence && (
                  <>
                    <Input
                      className="col-md-3"
                      name="value"
                      label="Valor"
                      rules={{ required: true }}
                      placeholder="0,00"
                      onChange={({ target }) =>
                        setValue(genericMaskWithTwoZeroWithPoint(target.value))
                      }
                      value={value}
                    />
                    <Input
                      className="col-md-3"
                      name="fees_fines_value"
                      label="Juros/Multa"
                      placeholder="0,00"
                      onChange={({ target }) =>
                        setFeesFinesValue(
                          genericMaskWithTwoZeroWithPoint(target.value)
                        )
                      }
                      value={feesFinesValue}
                    />
                  </>
                )}
                <Input
                  className="col-md-3"
                  name="nf_number"
                  label="Nº NF"
                  rules={{ required: true }}
                />
                <Select
                  className="col-md-3"
                  name="finished"
                  label="Realizado"
                  defaultValue=""
                  blank
                  options={[
                    {
                      name: 'Sim',
                      value: 'S'
                    },
                    {
                      name: 'Não',
                      value: 'N'
                    }
                  ]}
                />
                <Select
                  className="col-md-3"
                  name="payment_method_text"
                  label="Forma de Pagamento"
                  blank
                  options={paymentGateways}
                />
              </div>
            </FormContainer>
          ) : (
            <FormContainer className="form">
              <div className="row mb-5">
                <Datalist
                  label="Fornecedor"
                  classname="col-md-6"
                  setValue={(item: any) => setProviderId(item.id)}
                  data={providers}
                  value={initialValues?.provider?.company_social_name}
                />
                <Select
                  className="col-md-3"
                  name="category_id"
                  label="Categoria Financeira"
                  rules={{ required: true }}
                  options={financialCategories}
                  blank
                  defaultValue=""
                  onChange={event => handleFinacialCategory(event.target.value)}
                />
                <Select
                  className="col-md-3"
                  name="sub_category_id"
                  label="Sub Categoria Financeira"
                  rules={{ required: true }}
                  options={financialSubCategories}
                  blank
                  defaultValue=""
                />
              </div>
              <div className="row mb-5">
                <Textarea
                  className="col-md-12"
                  name="description"
                  label="Descrição"
                  style={{ minHeight: 120 }}
                />
              </div>
              <div className="row mb-5">
                <Input
                  className="col-md-2"
                  name="value"
                  label="Valor"
                  placeholder="0,00"
                  rules={{ required: true }}
                  onChange={event =>
                    setValue(
                      genericMaskWithTwoZeroWithPoint(event.target.value)
                    )
                  }
                  value={value}
                />
                <Input
                  className="col-md-2"
                  name="fees_fines_value"
                  label="Juros/Multa"
                  placeholder="0,00"
                  onChange={({ target }) =>
                    setFeesFinesValue(
                      genericMaskWithTwoZeroWithPoint(target.value)
                    )
                  }
                  value={feesFinesValue}
                />
                <Input
                  className="col-md-3"
                  name="nf_number"
                  label="Nº NF"
                  rules={{ required: true }}
                />
                <DatePicker
                  className="col-md-3"
                  name="due_date"
                  label="Data de Vencimento"
                  rules={{ required: true }}
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  minDate={new Date()}
                  controlled
                />
                <Select
                  className="col-md-2"
                  name="finished"
                  label="Realizado"
                  defaultValue=""
                  blank
                  options={[
                    {
                      name: 'Sim',
                      value: 'S'
                    },
                    {
                      name: 'Não',
                      value: 'N'
                    },
                    {
                      name: 'Cancelado',
                      value: 'C'
                    }
                  ]}
                />
              </div>
              <div className="row mb-5">
                <Select
                  className="col-md-3"
                  name="payment_method_text"
                  label="Forma de Pagamento"
                  blank
                  options={paymentGateways}
                />
              </div>
            </FormContainer>
          )}
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </>
    </Form>
  )
}
