import { useCallback, useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select, Textarea } from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { FormContainer } from './styles'
import { cnpjMask, phoneMask, zipCodeMask } from '../../../../../utlis/mask'
import jsonp from 'jsonp'
import { handleErrorMessage } from 'utlis/handleErros'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type ClientData = {
  street: string
  district: string
  city: string
  state: string
  ibge: string
  company_social_name: number
  company_name: string
  cnpj: string
  number: string
  complement: string
  email: string
  phone_1: string
  phone_2: string
  mobile: string
  note: string
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: ClientData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

type TableResponse = {
  id: number
  name: string
}
type TableProps = {
  value: number
  name: string
}
type Company = {
  company_social_name: string
  company_name: string
  cnpj: string
  number: string
  complement: string
  cnpjSearch: string
}
type Address = {
  street: string
  district: string
  city: string
  state: string
  ibge: string
}

export const FormClient = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()

  const [defaultValues, setDefaultValues] = useState<ClientData>()
  const [tables, setTables] = useState<TableProps[]>([])
  const [company, setCompany] = useState<Company>()
  const [address, setAddress] = useState<Address>()
  const [cnpj, setCnpj] = useState<string>()
  const [zipCode, setZipCode] = useState<string>()
  const [phone1, setPhone1] = useState<string>()
  const [phone2, setPhone2] = useState<string>()
  const [mobile, setMobile] = useState<string>()

  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        ...initialValues,
        cnpj: cnpjMask(initialValues?.cnpj || ''),
        phone_1: phoneMask(initialValues?.phone_1 || ''),
        phone_2: phoneMask(initialValues?.phone_2 || ''),
        mobile: phoneMask(initialValues?.mobile || '')
      })
    }
  }, [initialValues])

  const { activeLoading, disableLoading } = useLoading()

  async function getTables() {
    try {
      const response = await api.get('/users/salesTables/')
      setTables(
        response.data.map(
          (table: TableResponse) =>
            table && { name: table.name, value: table.id }
        )
      )
    } catch (error) {
      addToast({
        title: 'Erro ao carregar as tabelas',
        description:
          'Houve um erro ao carregar as tabelas, tente novamente mais tarde!',
        type: 'error'
      })
    }
  }
  const getDataCep = useCallback(
    async (zipCodeData: string) => {
      setZipCode(zipCodeMask(zipCodeData))
      const zipCodeSearch = zipCodeData?.replaceAll(/[.\-/]/g, '')
      if (zipCodeSearch.length === 8) {
        activeLoading()
        try {
          const response = await api.get(
            `https://viacep.com.br/ws/${zipCodeSearch}/json`,
            {
              timeout: 10000
            }
          )
          const { bairro, ibge, localidade, logradouro, uf } = response.data
          setAddress({
            city: localidade,
            district: bairro,
            state: uf,
            street: logradouro,
            ibge
          })
        } catch (error) {
          addToast({
            title: 'Erro ao carregar os dados para o cep',
            description:
              'Houve um erro ao carregar os dados para o cep, digite os dados manualmente!',
            type: 'error'
          })
        }
        disableLoading()
      }
    },
    [activeLoading, addToast, disableLoading]
  )
  const getDataCnpj = useCallback(
    async (cnpjData: string) => {
      setCnpj(cnpjMask(cnpjData))
      const cnpjSearch = cnpjData?.replaceAll(/[.\-/]/g, '')
      if (cnpjSearch.length === 14) {
        activeLoading()
        jsonp(
          `https://www.receitaws.com.br/v1/cnpj/${cnpjSearch}`,
          {
            timeout: 10000
          },
          (error: any, data: any) => {
            if (error) {
              addToast({
                title: 'Erro ao carregar os dados para o cnpj',
                description:
                  'Houve um erro ao carregar os dados para o cnpj, digite os dados manualmente!',
                type: 'error'
              })
            } else {
              setCompany({
                cnpjSearch: data,
                company_social_name: data.fantasia,
                company_name: data.nome,
                cnpj: cnpjSearch,
                complement: data.complemento,
                number: data.numero
              })
              getDataCep(data.cep)
            }
            disableLoading()
          }
        )
      }
    },
    [activeLoading, addToast, disableLoading, getDataCep]
  )

  useEffect(() => {
    getTables()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmitForm = async (data: ClientData) => {
    const id = initialValues?.idUpdate
    data.cnpj = cnpjMask(data.cnpj)
    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose, idParent } = isOpenInModal
          const dataCreate = {
            ...data,
            phone_1: data.phone_1?.replace(' ', ''),
            phone_2: data.phone_2?.replace(' ', ''),
            mobile: data.mobile?.replace(' ', ''),
            parent_id: idParent,
            ibge: address?.ibge,
            note: data.note?.length ? data.note : null,
            cnpjSearch: company?.cnpjSearch
          }
          activeLoading()
          try {
            await api.post(apiCreate(), dataCreate)
            handleOnClose()
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
          } catch (error: any) {
            addToast({
              ...handleErrorMessage(error)
            })
            handleOnClose()
            disableLoading()
            updateDataTable()
          }
        } else {
          try {
            const dataCreate = {
              ...data,
              phone_1: data.phone_1?.replace(' ', ''),
              phone_2: data.phone_2?.replace(' ', ''),
              mobile: data.mobile?.replace(' ', ''),
              ibge: address?.ibge,
              note: data.note?.length ? data.note : null,
              cnpjSearch: company?.cnpjSearch
            }
            activeLoading()
            await api.post(apiCreate(), dataCreate)
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
            history.push(nameActions.read.to)
          } catch (error: any) {
            addToast({
              ...handleErrorMessage(error)
            })
            disableLoading()
            updateDataTable()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataUpdate = {
            ...data,
            phone_1: data.phone_1?.replace(' ', ''),
            phone_2: data.phone_2?.replace(' ', ''),
            mobile: data.mobile?.replace(' ', ''),
            ibge: address?.ibge,
            note: data.note?.length ? data.note : null,
            cnpjSearch: company?.cnpjSearch
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error: any) {
            disableLoading()
            handleOnClose()
            addToast({
              ...handleErrorMessage(error)
            })
          }
        } else {
          const dataUpdate = {
            ...data,
            phone_1: data.phone_1?.replace(' ', ''),
            phone_2: data.phone_2?.replace(' ', ''),
            mobile: data.mobile?.replace(' ', ''),
            ibge: address?.ibge,
            note: data.note?.length ? data.note : null,
            cnpjSearch: company?.cnpjSearch
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error: any) {
            history.push(nameActions.read.to)
            addToast({
              ...handleErrorMessage(error)
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }

  return (
    <Form
      onSubmit={onSubmitForm}
      defaultValues={{
        ...defaultValues,
        ...address,
        ...company
      }}
    >
      <>
        <div className="card mb-5 mb-xl-10">
          <FormContainer className="form-body">
            <div className="row mb-5">
              <Select
                className=" col-md-3"
                name="table_id"
                label="Tabela"
                options={tables}
                blank
                defaultValue=""
                rules={{ required: true }}
              />
              <Input
                name="cnpj"
                className=" col-md-3"
                label="CNPJ"
                rules={{ required: true }}
                readOnly={typeForm === 'update'}
                value={cnpj}
                onChange={event => getDataCnpj(event.target.value)}
              />
              <Input
                name="company_name"
                className=" col-md-3"
                label="Razão Social"
                rules={{ required: true }}
                onChange={event =>
                  setCompany({
                    ...company,
                    company_name: event.target.value
                  })
                }
              />
              <Input
                name="company_social_name"
                className=" col-md-3"
                label="Nome Fantasia"
                rules={{ required: true, position: 'left' }}
                onChange={event =>
                  setCompany({
                    ...company,
                    company_social_name: event.target.value
                  })
                }
              />
            </div>
            <div className="row mb-5">
              <Input
                name="state_registration"
                className=" col-md-3"
                label="Inscrição Estadual"
                rules={{ required: true }}
              />
              <Input
                name="city_registration"
                className=" col-md-3"
                label="Inscrição Municipal"
                rules={{ required: true }}
              />
            </div>
            <div className="separator my-5" />
            <div className="row mb-5">
              <Input
                name="zip_code"
                className=" col-md-3"
                label="CEP"
                rules={{ required: true }}
                value={zipCode}
                onChange={event => getDataCep(event.target.value)}
                maxLength={9}
              />
              <Input
                name="street"
                className=" col-md-6"
                label="Logradouro"
                rules={{ required: true }}
              />
              <Input
                name="number"
                className=" col-md-3"
                label="Número"
                rules={{ required: true }}
              />
            </div>
            <div className="row mb-5">
              <Input
                name="complement"
                className=" col-md-3"
                label="Complemento"
                rules={{ required: true }}
              />
              <Input
                name="district"
                className=" col-md-3"
                label="Bairro"
                rules={{ required: true }}
              />
              <Input
                name="city"
                className=" col-md-3"
                label="Cidade"
                rules={{ required: true }}
              />
              <Input
                name="state"
                className=" col-md-3"
                label="UF"
                rules={{ required: true }}
              />
            </div>
            <div className="separator my-5" />
            <div className="row mb-5">
              <Input
                name="phone_1"
                className=" col-md-3"
                label="Telefone 1"
                value={phone1}
                onChange={event => setPhone1(phoneMask(event.target.value))}
                maxLength={15}
              />
              <Input
                name="phone_2"
                className=" col-md-3"
                label="Telefone 2"
                value={phone2}
                onChange={event => setPhone2(phoneMask(event.target.value))}
                maxLength={15}
              />
              <Input
                name="mobile"
                className=" col-md-3"
                label="Celular"
                value={mobile}
                onChange={event => setMobile(phoneMask(event.target.value))}
                maxLength={15}
              />
              <Input name="email" className=" col-md-3" label="E-mail" />
            </div>
            <div className="separator my-5" />
            <div className="row mb-5">
              <Select
                className=" col-md-3"
                name="shipment_method"
                label="Forma de Entrega"
                options={[
                  {
                    name: 'Moto Boy',
                    value: 'M'
                  },
                  {
                    name: 'Retirada na Loja',
                    value: 'L'
                  }
                ]}
                blank
                defaultValue=""
                rules={{ required: true }}
              />
              <Select
                className=" col-md-3"
                name="payment_method"
                label="Forma de Pagamento"
                options={[
                  {
                    name: 'Fatura',
                    value: 'F'
                  },
                  {
                    name: 'Dinheiro',
                    value: 'D'
                  },
                  {
                    name: 'Boleto',
                    value: 'B'
                  }
                ]}
                blank
                defaultValue=""
                rules={{ required: true }}
              />
              {/* <div className="col-md-3">
                <Input
                  name="password"
                  className=""
                  label="Senha de Acesso"
                  type="password"
                  rules={{ required: true }}
                />
              </div> */}
              <Select
                className=" col-md-3"
                name="active"
                label="Ativo"
                options={[
                  {
                    name: 'Sim',
                    value: 'S'
                  },
                  {
                    name: 'Não',
                    value: 'N'
                  }
                ]}
                blank
                defaultValue={1}
                rules={{ required: true }}
              />
            </div>
            <div className="separator my-5" />
            <div className="row">
              <Textarea
                name="note"
                className=" col-md-12"
                label="Observações"
                style={{ minHeight: 120 }}
                defaultValue=""
              />
            </div>
          </FormContainer>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </>
    </Form>
  )
}
