import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Container from '../../../../components/Container'
import { useLoading } from '../../../../hooks/loading'
import api from '../../../../services/api'
import { FormServices } from '../components/Form'
import { apiList } from '../domain/api'
import { nameActions } from '../domain/info'
import { breadcrumbUpdate } from '../domain/breadcrumb'
import { toolsUpdate } from '../domain/tools'
import { useToast } from '../../../../hooks/toast'

type Table = {
  id: number
  table_id: number
  unit_price: string
  wholesale_price: string
}
const Update = (): JSX.Element => {
  const { addToast } = useToast()
  const location = useLocation()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { activeLoading, disableLoading } = useLoading()
  const [serviceItem, setServiceItem] = useState<{
    id: string
    name: string
    description?: string
    price: string
    tables: {
      [key: number]: Table
    }
  }>()

  useEffect(() => {
    async function loadData() {
      activeLoading()
      try {
        const response = await api.get(apiList(id))
        const { data } = response
        const tables: {
          [key: number]: { unit_price: string; wholesale_price: string }
        } = {}
        data.tables?.forEach((table: Table) => {
          tables[table.table_id] = { ...table }
        })
        setServiceItem({ ...data, tables })
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o serviço',
          description:
            'Houve um error ao carregar o serviço, tente novamente mais tarde!'
        })
        if (path.includes('update')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadData()
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])
  return (
    <Container
      pageTitle={nameActions.update.name}
      breadcrumb={breadcrumbUpdate}
      tools={toolsUpdate}
    >
      <FormServices
        typeForm="update"
        initialValues={{
          idUpdate: Number(serviceItem?.id),
          ...serviceItem
        }}
      />
    </Container>
  )
}

export default Update
