import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select } from '../../../../../components/Form'
import { Date as DatePicker } from '../../../../../components/Form/date'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { cnpjMask, zipCodeMask } from '../../../../../utlis/mask'
import jsonp from 'jsonp'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type FiscalSettingData = {
  company_name: string
  cnpj: string
  city_registration: string
  state_registration?: string
  address: string
  nfse_env?: boolean
  nfse_rps_number?: number
  certified_file?: string
  certified_validate?: string
  certified_password: string
  nf_emission_due?: number
  dir?: number
  invoice_email_copy?: string
  active: string
  street?: string
  number?: string
  complement?: string
  district?: string
  city?: string
  state?: string
  zip_code?: string
  phone?: string
}
type Address = {
  street: string
  district: string
  city: string
  state: string
  ibge: string
}

type Company = {
  company_social_name: string
  company_name: string
  cnpj: string
  number: string
  complement: string
  cnpjSearch: string
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: FiscalSettingData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

export const FormFiscalSettings = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const [defaultValues, setDefaultValues] = useState<FiscalSettingData>()
  const [file, setFile] = useState<File>()
  const [zipCode, setZipCode] = useState<string>()
  const [address, setAddress] = useState<Address>()
  const [company, setCompany] = useState<Company>()
  const [cnpj, setCnpj] = useState<string>()
  const [startDate, setStartDate] = useState<Date>()

  function onChangeFileHandler(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) return
    const file = event.target.files[0]

    setFile(file)
  }

  useEffect(() => {
    if (initialValues) {
      setDefaultValues({ ...initialValues })
    }
  }, [initialValues])

  const { activeLoading, disableLoading } = useLoading()

  const getDataCep = useCallback(
    async (zipCodeData: string) => {
      setZipCode(zipCodeMask(zipCodeData))
      const zipCodeSearch = zipCodeData?.replaceAll(/[.\-/]/g, '')
      if (zipCodeSearch.length === 8) {
        activeLoading()
        try {
          const response = await api.get(
            `https://viacep.com.br/ws/${zipCodeSearch}/json`,
            {
              timeout: 10000
            }
          )
          const { bairro, ibge, localidade, logradouro, uf } = response.data
          setAddress({
            city: localidade,
            district: bairro,
            state: uf,
            street: logradouro,
            ibge
          })
        } catch (error) {
          addToast({
            title: 'Erro ao carregar os dados para o cep',
            description:
              'Houve um erro ao carregar os dados para o cep, digite os dados manualmente!',
            type: 'error'
          })
        }
        disableLoading()
      }
    },
    [activeLoading, addToast, disableLoading]
  )

  const getDataCnpj = useCallback(
    async (cnpjData: string) => {
      setCnpj(cnpjMask(cnpjData))
      const cnpjSearch = cnpjData?.replaceAll(/[.\-/]/g, '')
      if (cnpjSearch.length === 14) {
        activeLoading()
        jsonp(
          `https://www.receitaws.com.br/v1/cnpj/${cnpjSearch}`,
          {
            timeout: 10000
          },
          (error: any, data: any) => {
            if (error) {
              addToast({
                title: 'Erro ao carregar os dados para o cnpj',
                description:
                  'Houve um erro ao carregar os dados para o cnpj, digite os dados manualmente!',
                type: 'error'
              })
            } else {
              setCompany({
                cnpjSearch: data,
                company_social_name: data.fantasia,
                company_name: data.nome,
                cnpj: cnpjSearch,
                complement: data.complemento,
                number: data.numero
              })
              getDataCep(data.cep)
            }
            disableLoading()
          }
        )
      }
    },
    [activeLoading, addToast, disableLoading, getDataCep]
  )

  const onSubmitForm = async (data: FiscalSettingData) => {
    const id = initialValues?.idUpdate
    const formData = new FormData()
    data.certified_validate = undefined
    formData.append('certified_validate', startDate.toISOString())
    formData.append('certified_file', file)
    Object.entries(data).forEach(([key, value]) => {
      if (value) {
        formData.append(key, String(value))
      }
    })

    try {
      if (typeForm === 'create') {
        try {
          activeLoading()
          await api.post(apiCreate(), formData)
          disableLoading()
          addToast({
            type: 'success',
            title: 'Registro criado',
            description: 'Registro criado com sucesso'
          })
          history.push(nameActions.read.to)
        } catch (error) {
          addToast({
            type: 'error',
            title: 'Erro ao adicionar o registro',
            description:
              'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
          })
          disableLoading()
        }
      } else {
        try {
          activeLoading()
          await api.put(apiUpdate(String(id)), formData)
          disableLoading()
          history.push(nameActions.read.to)
          addToast({
            type: 'success',
            title: 'Registro atualizado',
            description: 'Registro alterado com sucesso'
          })
        } catch (error) {
          history.push(nameActions.read.to)
          addToast({
            type: 'error',
            title: 'Erro ao atualizar o registro',
            description:
              'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
          })
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }

  return (
    <Form
      onSubmit={onSubmitForm}
      defaultValues={{
        ...defaultValues,
        ...address,
        ...company
      }}
    >
      <div className="row">
        <Input
          label="Razão Social"
          name="company_name"
          className=" col-md-6"
          rules={{ required: true }}
        />
        <Input
          label="CNPJ"
          name="cnpj"
          className="col-md-3"
          value={cnpj}
          onChange={event => getDataCnpj(event.target.value)}
          rules={{ required: true }}
        />
        <Input
          label="Inscrição Estadual"
          name="state_registration"
          className="col-md-3"
          rules={{ required: true }}
        />
        <Input
          label="Inscrição Municipal"
          name="city_registration"
          className="col-md-3"
          rules={{ required: true }}
        />
        <Input
          label="Certificado Senha"
          name="certified_password"
          className="col-md-3"
          rules={{ required: true }}
        />
        <Input
          type="file"
          label="Certificado Digital"
          name="certified_file"
          className="col-md-6"
          onChange={onChangeFileHandler}
        />
        <DatePicker
          label="Validade do certificado"
          name="certified_validate"
          className="col-md-3"
          selected={startDate}
          onChange={date => setStartDate(date)}
          minDate={new Date()}
          controlled
        />
        <Select
          label="Ambiente"
          name="nfse_env"
          className="col-md-3"
          options={[
            { value: '1', name: 'Homologação' },
            { value: '0', name: 'Produção' }
          ]}
          rules={{ required: true }}
          blank
          defaultValue={''}
        />
      </div>
      <div className="separator my-5" />
      <div className="row">
        <Input
          name="zip_code"
          className="col-md-3"
          label="CEP"
          rules={{ required: true }}
          value={zipCode}
          onChange={event => getDataCep(event.target.value)}
          maxLength={9}
        />
        <Input label="Logradouro" name="street" className="col-md-3" />
        <Input label="Número" name="number" className="col-md-3" />
      </div>
      <div className="row mb-5">
        <Input label="Complemento" name="complement" className="col-md-3" />
        <Input
          label="Bairro"
          name="district"
          className="col-md-3"
          rules={{ required: true }}
        />
        <Input label="Cidade" name="city" className="col-md-3" />
        <Input label="UF" name="state" className="col-md-3" />
      </div>

      <div className="card-footer d-flex justify-content-end py-6 px-9">
        <button type="submit" className="btn btn-primary">
          Salvar
        </button>
      </div>
    </Form>
  )
}
