import React from 'react'
import avatar from '../../assets/media/avatars/300-1.jpg'
import squaresSVG from '../../assets/media/icons/duotune/general/gen025.svg'
import svg1 from '../../assets/media/icons/duotune/general/gen022.svg'
import svg2 from '../../assets/media/icons/duotune/communication/com012.svg'
import svg3 from '../../assets/media/icons/duotune/general/gen032.svg'
import { HeaderNotificationsMenu } from '../HeaderNotificationsMenu'
import { QuickLinks } from '../QuickLinks'
import { HeaderClientApplicationMenu } from '../HeaderClientApplicationMenu'
import { HeaderSearchMenu } from '../HeaderSearchMenu'

import { KTSVG } from '../../assets/helpers'
const Header: React.FC = () => {
  const toolbarButtonIconSizeClass = 'svg-icon-1'

  return (
    <div id="kt_header" className="header">
      <div
        className={
          'd-flex align-items-stretch justify-content-end container-fluid'
        }
      >
        <div className="d-flex align-items-stretch flex-shrink-0">
          <div className={'d-flex align-items-stretch'}>
            <HeaderSearchMenu />
          </div>
          <div className={'d-flex align-items-center'}>
            <div
              className={'btn btn-icon btn-active-light-primary btn-custom'}
              id="kt_activities_toggle"
            >
              <KTSVG path={svg3} className={toolbarButtonIconSizeClass} />
            </div>
          </div>
          <div className={'d-flex align-items-center'}>
            <div
              className={'btn btn-icon btn-active-light-primary btn-custom'}
              data-kt-menu-trigger="click"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="bottom-end"
              data-kt-menu-flip="bottom"
            >
              <KTSVG path={svg1} className={toolbarButtonIconSizeClass} />
            </div>
            <HeaderNotificationsMenu />
          </div>
          <div className={'d-flex align-items-center'}>
            <div
              className={
                'btn btn-icon btn-active-light-primary btn-custom position-relative'
              }
              id="kt_drawer_chat_toggle"
            >
              <KTSVG path={svg2} className={toolbarButtonIconSizeClass} />

              <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span>
            </div>
          </div>
          <div className={'d-flex align-items-center'}>
            <div
              className={'btn btn-icon btn-active-light-primary btn-custom'}
              data-kt-menu-trigger="click"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="bottom-end"
              data-kt-menu-flip="bottom"
            >
              <KTSVG path={squaresSVG} className={toolbarButtonIconSizeClass} />
            </div>
            <QuickLinks />
          </div>
          <div
            className={'d-flex align-items-stretch flex-shrink-0'}
            id="kt_header_user_menu_toggle"
          >
            <div
              className={
                'cursor-pointer symbol d-flex align-items-center ms-1 ms-lg-3'
              }
              data-kt-menu-trigger="click"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="bottom-end"
              data-kt-menu-flip="bottom"
            >
              <img src={avatar} alt="user" />
            </div>
            <HeaderClientApplicationMenu />

            <div
              className="d-flex align-items-center d-lg-none ms-2 me-n3"
              title="Show header menu"
            >
              <div
                className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
                id="kt_header_menu_mobile_toggle"
              >
                <KTSVG
                  path="/media/icons/duotune/text/txt001.svg"
                  className="svg-icon-1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
