import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select, Textarea } from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { FormContainer } from './styles'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type DownloadData = {
  id: number
  title: string
  description: string
  dir?: number
  attachment?: string
  attachment_url: string
  active: number
  user_id?: number
  created_at: string
  updated_at: string
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: DownloadData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

export const FormDownload = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [fileName, setFileName] = useState('')
  const [defaultValues, setDefaultValues] = useState<DownloadData>()
  const [file, setFile] = useState<File>()
  const [deleteFile, setDeleteFile] = useState(false)

  useEffect(() => {
    if (initialValues) {
      let filename = initialValues.attachment
      const indexTrace = filename?.indexOf('-', 0) + 1
      filename = filename?.slice(indexTrace, filename?.length)
      setFileName(filename)
      setDefaultValues({
        ...initialValues,
        active: initialValues.active ? 1 : 0
      })
    }
  }, [initialValues])

  const { activeLoading, disableLoading } = useLoading()

  const onSubmitForm = async (data: DownloadData) => {
    const id = initialValues?.idUpdate
    const formData = new FormData()
    console.log(file)
    formData.append('attachment', file)
    Object.entries(data).forEach(([key, value]) => {
      if (typeForm === 'update' && key === 'attachment') {
        return
      }
      if (key === 'attachment') {
        return
      }
      if (value) {
        formData.append(key, String(value))
      }
    })
    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal

          activeLoading()
          try {
            await api.post(apiCreate(), formData)
            handleOnClose()
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            handleOnClose()
            disableLoading()
            updateDataTable()
          }
        } else {
          try {
            activeLoading()
            await api.post(apiCreate(), formData)
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
            history.push(nameActions.read.to)
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            disableLoading()
            updateDataTable()
          }
        }
      } else {
        formData.append('deleteFile', `${deleteFile}`)
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), formData)
            updateDataTable()
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), formData)
            updateDataTable()
            disableLoading()
            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            history.push(nameActions.read.to)
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }

  function onChangeFileHandler(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) return
    const file = event.target.files[0]
    console.log(file)
    setFile(file)
  }

  const handleDownload = useCallback(() => {
    fetch(initialValues?.attachment_url).then(response => {
      response.blob().then((blobObject: any) => {
        console.log(blobObject)
        const url = window.URL.createObjectURL(blobObject)
        const a = document.createElement('a')
        a.download = fileName
        a.href = url
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      })
    })
  }, [fileName, initialValues?.attachment_url])

  const handleSetDeleteFile = useCallback(() => {
    setDeleteFile(true)
  }, [])

  return (
    <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
      <>
        <div className="card mb-5 mb-xl-10">
          <FormContainer className="form-body">
            <div className="row mb-5">
              <Input
                name="title"
                className=" col-md-12"
                label="Título"
                rules={{ required: true }}
              />
            </div>

            <div className="row mb-5">
              <Textarea
                name="description"
                className=" col-md-12"
                label="Descrição"
                style={{ minHeight: 120 }}
                defaultValue=""
              />
            </div>
            <div className="row mb-5">
              <div className="col-md-6">
                <Input
                  type="file"
                  label="Anexo"
                  name="attachment"
                  onChange={onChangeFileHandler}
                  rules={{ required: typeForm === 'create' }}
                />
                {!!fileName?.length && (
                  <a
                    href="#"
                    onClick={handleDownload}
                    style={{
                      display: 'flex',
                      alignItems: 'baseline',
                      width: 'fit-content',
                      marginBottom: '10px'
                    }}
                  >
                    <span
                      className="fa fa-download"
                      style={{ marginRight: '5px', fontSize: '14px' }}
                    ></span>
                    <span
                      className="fw-bolder fs-6"
                      style={{
                        display: 'flex',
                        alignSelf: 'end'
                      }}
                    >
                      {fileName}
                    </span>
                  </a>
                )}
                {!!fileName?.length && (
                  <a
                    className="btn btn-sm btn-light-danger fw-bolder noActive"
                    type="button"
                    onClick={handleSetDeleteFile}
                  >
                    Remover Anexo
                  </a>
                )}
              </div>
              {typeForm === 'update' && (
                <Select
                  className=" col-md-3"
                  name="active"
                  label="Ativo"
                  options={[
                    {
                      name: 'Sim',
                      value: 1
                    },
                    {
                      name: 'Não',
                      value: 0
                    }
                  ]}
                  blank
                  defaultValue=""
                  rules={{ required: true }}
                />
              )}
            </div>
          </FormContainer>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </>
    </Form>
  )
}
