import React, { useEffect, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { useUpdateDataTable } from '../../../../hooks/dataTable'
import { nameActions } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'
import Container from '../../../../components/Container'

interface PaymentGatewaysData {
  id: number
  module: string
  type: string
  credentials: any
  paymentModule: {
    name: string
  }
  created_at: string
  updated_at: string
}

const PaymentGatewaysView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const { updateDataTable } = useUpdateDataTable()
  const [paymentGateways, setPaymentGateways] = useState<PaymentGatewaysData>(
    {} as PaymentGatewaysData
  )
  const { addToast } = useToast()
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })
  const [PaymentGatewaysList, setPaymentGatewaysList] =
    useState<PaymentGatewaysData[]>()

  useEffect(() => {
    const PaymentGateways = PaymentGatewaysList?.find(
      PaymentGateways => PaymentGateways.id?.toString() === id
    )
    if (PaymentGateways) {
      PaymentGateways.credentials = JSON.stringify(PaymentGateways.credentials)
      setPaymentGateways(PaymentGateways)
    }
  }, [id, PaymentGatewaysList])

  const { disableLoading, activeLoading } = useLoading()

  useEffect(() => {
    async function loadPaymentGateways(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<PaymentGatewaysData[]>(apiList())
        const { data } = response
        setPaymentGatewaysList(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o PaymentGateways',
          description:
            'Houve um error ao carregar o PaymentGateways, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadPaymentGateways()
  }, [
    activeLoading,
    addToast,
    disableLoading,
    history,
    id,
    location.pathname,
    updateDataTable
  ])
  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
    addToast({
      type: 'info',
      title: 'Operação cancelada.'
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Atributo removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title: 'Atributo não removido, pois ainda está sendo usado.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Atributo removido com sucesso.'
      })
      history.goBack()
    } catch (err) {
      setAlertRemoveParent(false)
      addToast({
        type: 'error',
        title: 'Atributo não removido, pois ainda está sendo usado.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row mb-5">
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="id"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Cód.
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {paymentGateways?.id}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="module"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Módulo
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {paymentGateways?.paymentModule?.name}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="credentials"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Credenciais
                </label>
                <pre className="fw-bolder fs-6 text-gray-800">
                  {paymentGateways?.credentials}
                </pre>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="type"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Tipo
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {paymentGateways?.type}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="created"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Cadastrado em
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {paymentGateways?.created_at}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="updated"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Atualizado em
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {paymentGateways?.updated_at}
                </p>
              </div>
            </div>
          </div>
          <p>&nbsp;</p>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${alert.name} ?`}
        onClickCancelButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${paymentGateways?.module} ?`}
        onClickCancelButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(paymentGateways?.id))
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}

export default PaymentGatewaysView
