import React from 'react'
import Container from '../../../../components/Container'
import DataTable from '../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import { apiDelete } from '../domain/api'
import api from 'services/api'
import { useUpdateDataTable } from 'hooks/dataTable'
import { useLoading } from 'hooks/loading'
import { useToast } from 'hooks/toast'

const List = (): JSX.Element => {
  const { updateDataTable } = useUpdateDataTable()
  const { activeLoading, disableLoading } = useLoading()
  const { addToast } = useToast()
  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsList}
    >
      <DataTable
        source={nameSource}
        entity={nameEntity}
        format={{ orderBy: 'name' }}
        headers={headers}
        notParent={true}
        onActions={{
          onClickButtonRemove: async item => {
            activeLoading()
            try {
              await api.delete(apiDelete(String(item?.id)))
              updateDataTable()
              disableLoading()
            } catch (error: any) {
              if (error.response.status === 403) {
                addToast({
                  type: 'error',
                  title: error.response.data.message
                })
                return
              }
              addToast({
                type: 'error',
                title: 'Atributo não removido, pois ainda está sendo usado.'
              })
              disableLoading()
            }
          }
        }}
      />
    </Container>
  )
}

export default List
