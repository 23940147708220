import styled from 'styled-components'

export const Td = styled.td<{ selected?: boolean; active?: boolean }>`
  background-color: ${({ selected }) =>
    selected ? '#ccc!important' : 'white!important'};
  ${({ active }) => active && 'background-color: #72A0C1!important'};
`

export const Th = styled.td<{ selected?: boolean }>`
  background-color: ${({ selected }) =>
    selected ? '#ccc!important' : 'white!important'};
`
