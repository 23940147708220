/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useRef, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../components/Container'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { nameActions } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'
import { genericMaskWithTwoZeroWithPoint } from '../../../../utlis/mask'
import {
  toolsViewChangeDueDate,
  toolsViewManualDownload,
  toolsViewPaymentMethod
} from '../domain/tools/view'
import Modal from '../../../../components/Modal'
import { FormChangeDueDate } from '../components/FormChangeDueDate'
import { FormManualDownload } from '../components/FormManualDownload'

export interface IncomeData {
  category_id: number
  client_application_id: number
  client_id?: number
  created_at: string
  description: string
  due_date: string
  fees_fines_value: string
  financialCategory: {
    name: string
  }
  financialSubCategory: {
    name: string
  }
  finished: string
  generated_user_id: number
  generated_at: string
  generatedUser?: {
    username: string
  }
  downloaded_user_id: number
  downloaded_at: string
  downloadedUser: {
    username: string
  }
  id: number
  nf_number: number
  operation_type: string
  payment_method?: string
  payment_gateway_id?: number
  client: {
    company_name: string
    company_social_name: string
  }
  financialMovimentsPayments?: {
    document_number: string
    situation: 'Awaiting payment' | 'Paid' | 'Cancelled'
  }[]
  sub_category_id: number
  updated_at: string
  value: string
  products_value: string
  services_value: string
}

const IncomeView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const [income, setIncome] = useState<IncomeData | null>(null)
  const { addToast } = useToast()
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })
  const paymentMethodTypes: any = {
    CR: 'Crédito',
    F: 'Fatura',
    D: 'Dinheiro',
    C: 'Cheque',
    B: 'Boleto',
    T: 'Transferência Bancária'
  }
  const operationTypes: any = {
    D: 'Débito',
    C: 'Crédito'
  }
  const refModal = useRef(null)
  const refModalManualDownload = useRef(null)
  const [modalEditManualDownload, setModalEditManualDownload] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)

  const { disableLoading, activeLoading } = useLoading()

  async function loadIncome(): Promise<void> {
    activeLoading()
    try {
      const response = await api.get<IncomeData>(apiList(id))
      const { data } = response
      setIncome(data)
      disableLoading()
    } catch (err) {
      const [, baseUrl, path] = location.pathname.split('/')
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar o Receita',
        description:
          'Houve um error ao carregar o Receita, tente novamente mais tarde!'
      })
      if (path.includes('view')) {
        history.push(`/${baseUrl}`)
      } else {
        history.push(`/${baseUrl}/${path}`)
      }
    }
  }
  useEffect(() => {
    loadIncome()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoading, addToast, disableLoading, history, location.pathname])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
    addToast({
      type: 'info',
      title: 'Operação cancelada.'
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Receita removida com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title: 'Receita não removida, pois ainda está sendo usada.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Receita removida com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Receita não removida, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  const handleClickOnClose = async () => {
    await loadIncome()
    setModalEdit(false)
    setModalEditManualDownload(false)
  }
  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewChangeDueDate(income, () => setModalEdit(true)),
          toolsViewManualDownload(income, () =>
            setModalEditManualDownload(true)
          ),
          toolsViewPaymentMethod(income),
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row mb-5">
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="id"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Cód.
                </label>
                <p className="fw-bolder fs-6 text-gray-800">{income?.id}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  htmlFor="client"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Cliente
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {income?.client?.company_name}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="role_id"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Categoria Financeira
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {income?.financialCategory?.name}
                </p>
              </div>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="role_id"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Sub Categoria Financeira
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {income?.financialSubCategory?.name}
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-12">
              <div className="form-group">
                <label
                  htmlFor="description"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Descrição
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {income?.description}
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="value"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Valor Produtos
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {genericMaskWithTwoZeroWithPoint(income?.products_value)}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="value"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Valor Serviços
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {genericMaskWithTwoZeroWithPoint(income?.services_value)}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="value"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Valor Total
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {genericMaskWithTwoZeroWithPoint(income?.value)}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="fees_fines_value"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Juros / Multa
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {Number(income?.fees_fines_value).toLocaleString('pt-br', {
                    minimumFractionDigits: 2
                  })}
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="due_date"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Data de Vencimento
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {income?.due_date}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="nf_number"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Nº NF
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {income?.nf_number}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="payment_method"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Forma de Pagamento
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {paymentMethodTypes[income?.payment_method]}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="finished"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Realizado
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {income?.finished === 'S' ? 'Sim' : 'Não'}
                </p>
              </div>
            </div>
          </div>
          <div className="separator my-5" />
          <div className="row mb-5">
            <div className="col-md-3">
              <div className="form-group d-flex flex-column">
                <label
                  htmlFor="due_date"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Nota Fiscal Produto
                </label>
                <button className="btn btn-primary col-md-5" type="button">
                  Gerar XML
                </button>
              </div>
            </div>
          </div>
          <div className="separator my-5" />
          <div className="row mb-5">
            <div className="col-md-4">
              <div className="form-group">
                <label
                  htmlFor="operation_type"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Tipo de Operação
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {operationTypes[income?.operation_type]}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label
                  htmlFor="finished"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Baixado por
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {income?.downloadedUser?.username}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label
                  htmlFor="finished"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Baixado em
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {income?.downloaded_at}
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-4">
              <div className="form-group">
                <label
                  htmlFor="finished"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Gerado por
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {income?.generatedUser?.username}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label
                  htmlFor="finished"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Gerado em
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {income?.created_at}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label
                  htmlFor="finished"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Ultima atualização
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {income?.updated_at}
                </p>
              </div>
            </div>
          </div>
          <p className="fw-bolder fs-6 text-gray-800">&nbsp;</p>
        </div>
      </Container>
      <Modal
        refModal={refModal}
        onClickButtonCancel={handleClickOnClose}
        isOpenModal={modalEdit}
        pageTitle={'Editar'}
        Children={
          <FormChangeDueDate
            typeForm="update"
            initialValues={{
              idUpdate: Number(id)
            }}
            isOpenInModal={{
              handleOnClose: handleClickOnClose,
              idParent: Number(id)
            }}
          />
        }
      />
      <Modal
        refModal={refModalManualDownload}
        onClickButtonCancel={handleClickOnClose}
        isOpenModal={modalEditManualDownload}
        pageTitle={'Baixa Manual'}
        Children={
          <FormManualDownload
            typeForm="update"
            initialValues={{
              idUpdate: Number(id)
            }}
            isOpenInModal={{
              handleOnClose: handleClickOnClose,
              idParent: Number(id)
            }}
          />
        }
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${alert.id} ?`}
        onClickCancelButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${income?.id} ?`}
        onClickCancelButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(income?.id))
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}

export default IncomeView
