/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../components/Container'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { nameActions } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'
import { useAuth } from '../../../../hooks/auth'
import { cnpjMask, phoneMask } from '../../../../utlis/mask'

interface ProviderData {
  id: number
  company_social_name: string
  company_name: string
  cnpj?: string
  phone: string
  mobile?: string
  email?: string
  street?: string
  number?: string
  complement?: string
  district?: string
  zip_code?: string
  city?: string
  state?: string
  ibge?: number
  note?: string
  active: string
  created_at: string
  updated_at: string
}

const View: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const { clientApplication } = useAuth()
  const [provider, setProvider] = useState<ProviderData | null>(null)
  const { addToast } = useToast()
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })

  const { disableLoading, activeLoading } = useLoading()

  useEffect(() => {
    async function loadProvider(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<ProviderData>(apiList(id))
        const { data } = response
        setProvider(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o Grupo de usuário',
          description:
            'Houve um error ao carregar o grupo, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadProvider()
  }, [
    addToast,
    activeLoading,
    disableLoading,
    id,
    location.pathname,
    history,
    clientApplication.id
  ])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
    addToast({
      type: 'info',
      title: 'Operação cancelada.'
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Fornecedor removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title: 'Fornecedor não removido, pois ainda está sendo usado.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Fornecedor removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Fornecedor não removido, pois ainda está sendo usado.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row mb-5">
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="id"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Cód.
                </label>
                <p className="fw-bolder fs-6 text-gray-800">{provider?.id}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="Cnpj"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Cnpj
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {cnpjMask(provider?.cnpj || '')}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  htmlFor="company_name"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Razão Social
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {provider?.company_name}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="company_social_name"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Nome Fantasia
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {provider?.company_social_name}
                </p>
              </div>
            </div>
          </div>
          <div className="separator my-5" />
          <div className="row mb-5">
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="street"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Logradouro
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {provider?.street}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="district"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Bairro
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {provider?.district}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="city"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Cidade
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {provider?.city} | {provider?.state}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="zip_code"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  CEP
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {provider?.zip_code}
                </p>
              </div>
            </div>
          </div>
          <div className="separator my-5" />

          <div className="row mb-5">
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="phone_1"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Telefone
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {phoneMask(provider?.phone || '')}
                </p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="mobile"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Celular
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {phoneMask(provider?.mobile || '')}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="email"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  E-mail
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {provider?.email}
                </p>
              </div>
            </div>
          </div>

          <div className="separator my-5" />
          <div className="row mb-5">
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="active"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Ativo
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {provider?.active === 'S' ? 'Sim' : 'Não'}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="created"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Cadastrado em
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {provider?.created_at}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="updated"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Atualizado em
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {provider?.updated_at}
                </p>
              </div>
            </div>
          </div>
          <div className="separator my-5" />
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label
                  htmlFor="note"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Observações
                </label>
                <p className="fw-bolder fs-6 text-gray-800">{provider?.note}</p>
              </div>
            </div>
          </div>
          <p className="fw-bolder fs-6 text-gray-800">&nbsp;</p>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${alert.name} ?`}
        onClickCancelButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${provider?.company_social_name} ?`}
        onClickCancelButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(provider?.id))
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}

export default View
