import DownloadsList from '../../pages/Commercial/Downloads/List'
import DownloadsUpdate from '../../pages/Commercial/Downloads/Update'
import DownloadsCreate from '../../pages/Commercial/Downloads/Create'
import DownloadsView from '../../pages/Commercial/Downloads/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const DownloadsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/commercial/downloads" exact component={DownloadsList} />
    <Route
      path="/commercial/downloads/create"
      component={DownloadsCreate}
      exact
    />
    <Route
      path="/commercial/downloads/view/:id"
      component={DownloadsView}
      exact
    />
    <Route
      path="/commercial/downloads/update/:id"
      component={DownloadsUpdate}
      exact
    />
  </CustomSwitch>
)
