import FamiliesList from '../../pages/Warehouse/Families/List'
import FamiliesUpdate from '../../pages/Warehouse/Families/Update'
import FamiliesCreate from '../../pages/Warehouse/Families/Create'
import FamiliesView from '../../pages/Warehouse/Families/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const FamiliesRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/warehouse/families" exact component={FamiliesList} />
    <Route path="/warehouse/families/create" component={FamiliesCreate} exact />
    <Route path="/warehouse/families/view/:id" component={FamiliesView} exact />
    <Route
      path="/warehouse/families/update/:id"
      component={FamiliesUpdate}
      exact
    />
  </CustomSwitch>
)
