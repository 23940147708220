import Container from '../../../../components/Container'
import DataTable from '../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'

const List = (): JSX.Element => {
  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsList}
    >
      <DataTable
        source={nameSource}
        entity={nameEntity}
        format={{ orderBy: 'name' }}
        headers={headers}
      />
    </Container>
  )
}

export default List
