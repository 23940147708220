import React, { useState } from 'react'
import { Input } from '../Form'

interface DatalistProps {
  label: string
  value?: string
  data: {
    name: string
    value: number
    id: number
  }[]
  classname?: string
  setValue: (item: any) => void
}
export const Datalist: React.FC<DatalistProps> = ({
  label,
  setValue,
  classname,
  data,
  value
}) => {
  const [searchValues, setSearchValues] = useState({
    name: '',
    value: 0,
    id: 0
  })
  const [selected, setSelected] = useState(false)
  const [display, setDisplay] = useState(false)
  return (
    <div className={classname || 'col-md-3'}>
      <Input
        name=""
        label={label}
        onChange={({ target }) => {
          setSearchValues(prev => {
            const copy: any = { ...prev }
            copy.name = target.value
            return copy
          })
          setSelected(false)
          setDisplay(true)
        }}
        value={searchValues.name || value}
      />
      {display && (
        <div
          className="card container-dropdown border scroll"
          style={{ maxHeight: '165px' }}
        >
          <div className="d-flex flex-column px-5">
            {searchValues.name !== '' &&
              !selected &&
              data
                .filter(item =>
                  item.name?.toLowerCase().includes(searchValues.name)
                )
                .map(item => (
                  <span
                    key={item.id}
                    className="fw-bolder fs-6 text-gray-800 cursor-pointer text-hover-primary py-5"
                    onClick={() => {
                      setSearchValues(prev => {
                        const copy = { ...prev }
                        copy.name = item.name
                        copy.id = item.id
                        return copy
                      })
                      setValue(item)
                      setSelected(true)
                      setDisplay(false)
                    }}
                  >
                    {item.name}
                  </span>
                ))}
          </div>
        </div>
      )}
    </div>
  )
}
