import { Redirect, Route, Switch } from 'react-router-dom'
import { NotFoundPage } from '../pages/NotFound'
import SignUp from '../pages/Signup'
import Dashboard from '../pages/Dashboard'
import PrivateRoutes from './Route'
import SignIn from '../pages/Signin'
import { MenusRouter } from './menus/MenusRouter'
import { SalesTablesRouter } from './users/SalesTablesRouter'
import { ProvidersRouter } from './commercial/ProvidersRouter'
import { OrdersRouter } from './commercial/OrdersRouter'
import { UnitTypesRouter } from './warehouse/UnitTypesRouter'
import { FamiliesRouter } from './warehouse/FamiliesRouter'
import { GroupsRouter } from './warehouse/GroupsRouter'
import { ServicesRouter } from './warehouse/ServicesRouter'
import { useAuth } from '../hooks/auth'
import { ClientsRouter } from './commercial/ClientsRouter'
import { BankAccountsRouter } from './financial/BankAccountsRouter'
import { FiscalSettingsRouter } from './financial/FiscalSettingsRouter'
import { FinancialMovimentsTypesGroupsRouter } from './financial/FinancialMovimentsTypesGroupsRouter'
import { ClientsApplicationsUsersRouter } from './users/ClientsApplicationsUsersRouter'
import { ForgotPassword } from '../pages/ForgotPassword'
import { DownloadsRouter } from './commercial/DownloadsRouter'
import { ExpensesRouter } from './financial/ExpensesRouter'
import { FinancialCategoriesRouter } from './financial/FinancialCategoryRouter'
import { ExtractRouter } from './financial/ExtractRouter'
import { PaymentGatewaysRouter } from './financial/PaymentGateways'
import { IncomesRouter } from './financial/IncomesRouter'

const Routes: React.FC = () => {
  const { clientApplicationLogged, backupHistory } = useAuth()
  return (
    <Switch>
      <Route path="/" exact component={SignIn}>
        {!backupHistory.length && clientApplicationLogged() && (
          <Redirect to="/dashboard" />
        )}
      </Route>

      <Route path="/signup" component={SignUp} />
      <PrivateRoutes path="/dashboard" component={Dashboard} />
      <Route path="/menus" component={MenusRouter} />
      <Route path="/users/salesTables" component={SalesTablesRouter} />
      <Route path="/commercial/providers" component={ProvidersRouter} />
      <Route path="/commercial/orders" component={OrdersRouter} />
      <Route path="/warehouse/unitTypes" component={UnitTypesRouter} />
      <Route path="/warehouse/families" component={FamiliesRouter} />
      <Route path="/warehouse/groups" component={GroupsRouter} />
      <Route path="/warehouse/services" component={ServicesRouter} />
      <Route
        path="/users/clientsApplicationsUsers"
        component={ClientsApplicationsUsersRouter}
      />
      <Route path="/commercial/clients" component={ClientsRouter} />
      <Route path="/commercial/downloads" component={DownloadsRouter} />
      <Route path="/financial/bankAccounts" component={BankAccountsRouter} />
      <Route
        path="/financial/fiscalSettings"
        component={FiscalSettingsRouter}
      />
      <Route path="/financial/expenses" component={ExpensesRouter} />
      <Route path="/financial/extract" component={ExtractRouter} />
      <Route
        path="/financial/financialMovimentsTypesGroups"
        component={FinancialMovimentsTypesGroupsRouter}
      />
      <Route
        path="/financial/categories"
        component={FinancialCategoriesRouter}
      />
      <Route
        path="/financial/paymentGateways"
        component={PaymentGatewaysRouter}
      />
      <Route path="/financial/incomes" component={IncomesRouter} />
      <Route path="/forgot" component={ForgotPassword} />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  )
}
export default Routes
