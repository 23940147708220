import UnitTypesList from '../../pages/Warehouse/UnitTypes/List'
import UnitTypesUpdate from '../../pages/Warehouse/UnitTypes/Update'
import UnitTypesCreate from '../../pages/Warehouse/UnitTypes/Create'
import UnitTypesView from '../../pages/Warehouse/UnitTypes/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const UnitTypesRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/warehouse/unitTypes" exact component={UnitTypesList} />
    <Route
      path="/warehouse/unitTypes/create"
      component={UnitTypesCreate}
      exact
    />
    <Route
      path="/warehouse/unitTypes/view/:id"
      component={UnitTypesView}
      exact
    />
    <Route
      path="/warehouse/unitTypes/update/:id"
      component={UnitTypesUpdate}
      exact
    />
  </CustomSwitch>
)
