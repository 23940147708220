export const headers: {
  name: string
  field: string
  sortable: boolean
  download?: {
    fileName?: string
    fileUrl?: string
  }
  hasTypes?: {
    types?: any
  }
  concat?: {
    field?: string
    position?: 'start' | 'end'
  }
  style?: any
}[] = [
  {
    name: 'Data',
    field: 'downloaded_at',
    sortable: true,
    hasTypes: {
      types: {
        date: 'date'
      }
    },
    style: {
      width: 'auto'
    }
  },
  {
    name: 'Histórico',
    field: 'extractDescription',
    sortable: true,
    style: {
      width: 'auto'
    }
  },
  {
    name: 'Valor',
    field: 'value',
    sortable: true,
    concat: {
      field: 'operation_type',
      position: 'end'
    },
    hasTypes: {
      types: {
        monetary: 'monetary'
      }
    }
  },
  {
    name: 'Saldo',
    field: 'balance',
    sortable: true
  }
]
