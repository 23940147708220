import { useState } from 'react'
import api from '../../../../../services/api'
import Form, { Select, Textarea } from '../../../../../components/Form'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { apiUpdate } from '../../domain/api'
import { FormContainer } from './styles'
import { Date } from '../../../../../components/Form/date'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type IncomeData = {
  client_id?: number
  client?: {
    company_social_name: string
  }
  financial_moviment_type_id: number
  category_id: number
  sub_category_id: number
  description: string
  due_date?: Date
  value: string
  fees_fines_value?: string
  nf_number?: number
  finished: string
  payment_method?: string
  payment_method_text?: string
  recurrence?: number
  operation_type: string
  generated_user_id?: number
  payment_gateway_id?: number
  downloaded_at?: Date
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

export const FormManualDownload = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()

  const [startDate, setStartDate] = useState<Date>()
  const { activeLoading, disableLoading } = useLoading()

  const onSubmitForm = async (data: IncomeData) => {
    const id = initialValues?.idUpdate
    data.finished = 'S'
    try {
      if (isOpenInModal) {
        const { handleOnClose } = isOpenInModal
        const dataUpdate = {
          ...data
        }

        try {
          activeLoading()
          await api.put(apiUpdate(String(id)), dataUpdate)

          disableLoading()
          handleOnClose()
          addToast({
            type: 'success',
            title: 'Registro atualizado',
            description: 'Registro alterado com sucesso'
          })
        } catch (error) {
          disableLoading()
          handleOnClose()
          addToast({
            type: 'error',
            title: 'Erro ao atualizar o registro',
            description:
              'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
          })
        }
      }
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }

  return (
    <Form onSubmit={onSubmitForm}>
      <>
        <div className="card mb-5 mb-xl-10">
          <FormContainer className="form">
            <div className="row mb-5">
              <Select
                className="col-md-6"
                name="payment_method"
                label="Forma de Pagamento"
                blank
                defaultValue=""
                options={[
                  { value: 'F', name: 'Fatura' },
                  { value: 'D', name: 'Dinheiro' },
                  { value: 'C', name: 'Cheque' },
                  { value: 'B', name: 'Boleto' },
                  { value: 'T', name: 'Transferência Bancária' }
                ]}
              />
              <Date
                className="col-md-6"
                name="downloaded_at"
                label="Data de Recebimento"
                rules={{ required: true }}
                selected={startDate}
                onChange={date => setStartDate(date)}
                controlled
              />
            </div>
            <div className="row mb-5">
              <Textarea
                className="col-md-12"
                name="description"
                label="Observação"
                style={{ minHeight: 120 }}
              />
            </div>
          </FormContainer>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </>
    </Form>
  )
}
