import ExpensesList from '../../pages/Financial/Expenses/List'
import ExpensesUpdate from '../../pages/Financial/Expenses/Update'
import ExpensesCreate from '../../pages/Financial/Expenses/Create'
import ExpensesView from '../../pages/Financial/Expenses/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const ExpensesRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/financial/expenses" exact component={ExpensesList} />
    <Route path="/financial/expenses/create" component={ExpensesCreate} exact />
    <Route path="/financial/expenses/view/:id" component={ExpensesView} exact />
    <Route
      path="/financial/expenses/update/:id"
      component={ExpensesUpdate}
      exact
    />
  </CustomSwitch>
)
