import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'

import Form, { Input, Select } from '../../../../../components/Form'
import Button from '../../../../../components/Button'

type GroupData = {
  parent_id?: number
  user_id?: number
  name: string
  description?: string
  ncm?: number
  cst?: number
  cfop?: number
  cfop_out_of_state?: number
  unit_type_id?: number
  lense_type?: string
  lense_side?: string
  price?: string
  spherical_start?: number
  spherical_end?: number
  cylindrical_start?: number
  cylindrical_end?: number
  addition_start?: number
  addition_end?: number
  online?: string
  dir?: number
  cover?: string
  tables: {
    [key: number]: { wholesale_price: string; unit_price: string }
  }
}

type Family = {
  id: number
  name: string
}

type Table = {
  id: number
  name: string
}

type UnitType = {
  id: number
  name: string
}

type TypesFormProps = {
  initialValues?: GroupData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

export const FormGroups = ({
  typeForm,
  initialValues
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const [defaultValues, setDefaultValues] = useState<GroupData>()
  const { activeLoading, disableLoading } = useLoading()
  const [families, setFamilies] = useState<Family[]>([])
  const [tables, setTables] = useState<Table[]>([])
  const [unitTypes, setUnitTypes] = useState<UnitType[]>([])
  // const [file, setFile] = useState<File>()
  const [lenseType, setLenseType] = useState<string>('S')

  function onChangeFileHandler() {
    // if (!event.target.files) return
    // const file = event.target.files[0]
    // setFile(file)
  }

  useEffect(() => {
    if (initialValues) {
      setDefaultValues({ ...initialValues })
      if (initialValues.lense_type) {
        setLenseType(initialValues.lense_type)
      }
    }
  }, [initialValues])

  useEffect(() => {
    ;(async () => {
      const families = await api.get(
        'warehouse/productCategories?parent_id=null'
      )
      const unitTypes = await api.get('warehouse/unitTypes')
      const tables = await api.get('users/salesTables')
      setFamilies(families.data)
      setUnitTypes(unitTypes.data)
      setTables(tables.data)
    })()
  }, [])

  const onSubmit = async (data: GroupData) => {
    const tables = Object.entries(data.tables || {}).map(([key, table]) => ({
      table_id: key,
      ...table
    }))
    const a = {
      parent_id: data.parent_id,
      user_id: data.user_id,
      name: data.name,
      description: data.description,
      ncm: data.ncm,
      cst: data.cst,
      cfop: data.cfop,
      cfop_out_of_state: data.cfop_out_of_state,
      unit_type_id: data.unit_type_id,
      lense_type: data.lense_type,
      lense_side: data.lense_side,
      price: data.price,
      spherical_start: data.spherical_start,
      spherical_end: data.spherical_end,
      cylindrical_start: data.cylindrical_start,
      cylindrical_end: data.cylindrical_end,
      addition_start: data.addition_start,
      addition_end: data.addition_end,
      online: data.online,
      dir: data.dir,
      cover: data.cover,
      tables
    }
    try {
      if (typeForm === 'create') {
        try {
          const dataCreate = {
            ...a
          }
          activeLoading()
          await api.post(apiCreate(), dataCreate)
          disableLoading()
          addToast({
            type: 'success',
            title: 'Registro criado',
            description: 'Registro criado com sucesso'
          })
          history.push(nameActions.read.to)
        } catch (error) {
          addToast({
            type: 'error',
            title: 'Erro ao adicionar o registro',
            description:
              'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
          })
          disableLoading()
        }
      } else {
        const dataUpdate = {
          ...a
        }
        const id = initialValues?.idUpdate

        try {
          activeLoading()
          await api.put(apiUpdate(String(id)), dataUpdate)
          disableLoading()
          history.push(nameActions.read.to)
          addToast({
            type: 'success',
            title: 'Registro atualizado',
            description: 'Registro alterado com sucesso'
          })
        } catch (error) {
          history.push(nameActions.read.to)
          addToast({
            type: 'error',
            title: 'Erro ao atualizar o registro',
            description:
              'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
          })
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
      }
    }
  }

  function buildRange(start: number, end: number, increment: number): string[] {
    const result: string[] = []
    for (let index = start; index <= end; index += increment) {
      result.push(index.toFixed(2))
    }
    return result
  }

  return (
    <div className="card mb-5 mb-xl-10">
      <Form onSubmit={onSubmit} defaultValues={defaultValues}>
        <h2>Detalhes</h2>
        <div className="row">
          <Select
            label="Familia"
            name="parent_id"
            className="col-md-2"
            options={families.map(family => ({
              name: family.name,
              value: family.id
            }))}
            blank
            defaultValue={''}
          />
          <Input
            label="Nome"
            name="name"
            className="col-md-3"
            rules={{ required: true }}
          />
          <Select
            label="Unidade"
            name="unit_type_id"
            className="col-md-2"
            options={unitTypes.map(unit => ({
              name: unit.name,
              value: unit.id
            }))}
            blank
            defaultValue=""
            rules={{ required: true }}
          />
          <Select
            label="Disponível online"
            name="online"
            className="col-md-2"
            options={[
              { name: 'Sim', value: 'S' },
              { name: 'Não', value: 'N' }
            ]}
            blank
            defaultValue=""
            rules={{ required: true }}
          />
          <Input
            type="file"
            label="Capa - Dimensão 250x250px"
            name="certified_file"
            className="col-md-3"
            onChange={onChangeFileHandler}
          />
        </div>
        <div className="separator my-7" />
        <h2>Valores</h2>
        <div className="row">
          <Input
            label="Preço de custo"
            name="price"
            className="col-md-3"
            rules={{ required: true }}
            price
          />
        </div>
        <div className="row">
          <div className="col-md-8">
            <h6 className="mt-3">Tabelas</h6>
            <div className="row fs-6">
              <div className="col-md-2"></div>
              <div className="col-md-3 mb-2">Preço unitário</div>
              <div className="col-md-3">Preço atacado</div>
            </div>
            {tables?.map(table => (
              <div className="row" key={table.id}>
                <div className="col-md-2">
                  <span className="mt-5">{table.name}</span>
                </div>
                <div className="col-md-3">
                  <Input name={`tables.${table.id}.unit_price`} price />
                </div>
                <div className="col-md-3">
                  <Input name={`tables.${table.id}.wholesale_price`} price />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="separator my-7" />
        <h2>Fiscal</h2>
        <div className="row">
          <Input
            label="NCM/SH"
            name="ncm"
            className="col-md-3"
            rules={{ required: true }}
          />
          <Input
            label="CST"
            name="cst"
            className="col-md-3"
            rules={{ required: true }}
          />
          <Input
            label="CFOP"
            name="cfop"
            className="col-md-3"
            rules={{ required: true }}
          />
          <Input
            label="CFOP Outro Estado"
            name="cfop_out_of_state"
            className="col-md-3"
            rules={{ required: true }}
          />
        </div>
        <div className="separator my-7" />
        <h2>Variações</h2>
        <div className="row">
          <Select
            label="Tipo da lente"
            name="lense_type"
            className="col-md-2"
            value={lenseType}
            onChange={e => setLenseType(e.target.value)}
            options={[
              { name: 'Multifocal', value: 'M' },
              { name: 'Visão simples', value: 'S' }
            ]}
            rules={{ required: true }}
          />
        </div>
        {lenseType === 'S' ? (
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <h6>Variação Esférica</h6>
              </div>
              <div className="row">
                <Select
                  label="Início"
                  name="spherical_start"
                  className="col-md-6"
                  options={buildRange(-20, 20, 0.25).map(i => ({
                    name: i,
                    value: i
                  }))}
                  blank
                  defaultValue=""
                  rules={{ required: true }}
                />
                <Select
                  label="Término"
                  name="spherical_end"
                  className="col-md-6"
                  options={buildRange(-20, 20, 0.25).map(i => ({
                    name: i,
                    value: i
                  }))}
                  blank
                  defaultValue=""
                  rules={{ required: true }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <h6>Variação Cilíndrica</h6>
              </div>
              <div className="row">
                <Select
                  label="Início"
                  name="cylindrical_start"
                  className="col-md-6"
                  options={buildRange(-20, 0, 0.25).map(i => ({
                    name: i,
                    value: i
                  }))}
                  blank
                  defaultValue=""
                  rules={{ required: true }}
                />
                <Select
                  label="Término"
                  name="cylindrical_end"
                  className="col-md-6"
                  options={buildRange(-20, 0, 0.25).map(i => ({
                    name: i,
                    value: i
                  }))}
                  blank
                  defaultValue=""
                  rules={{ required: true }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="col-md-12">
            <div className="row">
              <h6>Variação Adição</h6>
            </div>
            <div className="row">
              <Select
                label="Lado"
                name="lense_side"
                className="col-md-4"
                options={[
                  { name: 'Ambos', value: 'A' },
                  { name: 'Esquerdo', value: 'E' },
                  { name: 'Direito', value: 'D' }
                ]}
                rules={{ required: true }}
              />
              <Select
                label="Inicio"
                name="addition_start"
                className="col-md-4"
                options={buildRange(1, 3.5, 0.25).map(i => ({
                  name: i,
                  value: i
                }))}
                blank
                defaultValue=""
                rules={{ required: true }}
              />
              <Select
                label="Término"
                name="addition_end"
                className="col-md-4"
                options={buildRange(1.25, 3.5, 0.25).map(i => ({
                  name: i,
                  value: i
                }))}
                blank
                defaultValue=""
                rules={{ required: true }}
              />
            </div>
          </div>
        )}
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <Button type="submit" className="btn btn-primary">
            Salvar
          </Button>
        </div>
      </Form>
    </div>
  )
}
