import IncomesList from '../../pages/Financial/Incomes/List'
import IncomesUpdate from '../../pages/Financial/Incomes/Update'
import IncomesCreate from '../../pages/Financial/Incomes/Create'
import IncomesView from '../../pages/Financial/Incomes/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const IncomesRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/financial/incomes" exact component={IncomesList} />
    <Route path="/financial/incomes/create" component={IncomesCreate} exact />
    <Route path="/financial/incomes/view/:id" component={IncomesView} exact />
    <Route
      path="/financial/incomes/update/:id"
      component={IncomesUpdate}
      exact
    />
  </CustomSwitch>
)
