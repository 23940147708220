import styled from 'styled-components'

export const FormContainer = styled.div`
  input::-webkit-calendar-picker-indicator {
    opacity: 0;
  }
`

export const SearchContainer = styled.div<{ erro?: boolean }>`
  position: relative;
  margin-bottom: 2rem;

  ul {
    width: 100%;
    max-height: 350px;
    margin: 0;
    padding: 0;

    position: absolute;

    border: ${props => props.erro && '1px solid red'};
    color: ${props => (props.erro ? 'red' : '#000')};
    background: #fff;

    overflow: auto;
    z-index: 999;

    li {
      font-size: 14px;
      padding: 3px;
      cursor: pointer;

      &:hover {
        background: #f1f2f3;
      }
    }
  }
`
