import React, { createContext, useCallback, useState, useContext } from 'react'
import jwtDecode, { JwtPayload } from 'jwt-decode'

import api from '../services/api'

interface ClientApplication {
  id: string
  name: string
  username: string
  password: string
  avatar_url: string
  client_application_role_id: number
  client_application_id: number
}

interface Menu {
  id: number
  parent_id?: number
  method?: string
  name: string
  url?: string
  permission: boolean
  children?: Menu[]
  type: string
}

interface AuthState {
  token: string
  clientApplication: ClientApplication
  menus: Menu[]
}

interface SingInCredentials {
  email: string
  password: string
}

interface AuthContextData {
  clientApplication: ClientApplication
  menus: Menu[]
  backupHistory: string
  signIn(credentials: SingInCredentials): Promise<void>
  signOut(): void
  clientApplicationLogged(): boolean
  updateClientApplication(): void
  setHistory(string: string): void
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export const AuthProvider: React.FC = ({ children }) => {
  const [backupHistory, setBackupHistory] = useState('')
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@OpticalLaboratory:token')
    const clientApplication = localStorage.getItem(
      '@OpticalLaboratory:clientApplication'
    )
    const menus = localStorage.getItem('@OpticalLaboratory:menus')

    if (token && clientApplication && menus) {
      api.defaults.headers.authorization = `Bearer ${token}`
      return {
        token,
        clientApplication: JSON.parse(clientApplication),
        menus: JSON.parse(menus)
      }
    }
    return {} as AuthState
  })

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('sessions/clientApplicationUser', {
      email,
      password
    })

    const {
      token,
      clientApplicationUser: clientApplication,
      menus
    } = response.data

    localStorage.setItem('@OpticalLaboratory:token', token)
    localStorage.setItem(
      '@OpticalLaboratory:clientApplication',
      JSON.stringify(clientApplication)
    )
    localStorage.setItem('@OpticalLaboratory:menus', JSON.stringify(menus))

    api.defaults.headers.authorization = `Bearer ${token}`

    setData({ token, clientApplication, menus })
  }, [])

  const setHistory = useCallback((history: string) => {
    setBackupHistory(history)
  }, [])
  const clientApplicationLogged = useCallback(() => {
    const token = localStorage.getItem('@OpticalLaboratory:token')
    if (token) {
      const decode = jwtDecode<JwtPayload>(token)
      const currentDate = Date.now().valueOf() / 1000
      if (decode.exp < currentDate) {
        return false
      } else {
        return true
      }
    }
    return false
  }, [])

  const updateClientApplication = useCallback(async () => {
    const clientApplication = await (await api.get('/profile/show')).data

    setData({ token: data.token, clientApplication, menus: data.menus })

    localStorage.setItem(
      '@OpticalLaboratory:clientApplication',
      JSON.stringify(clientApplication)
    )
  }, [data.menus, data.token])

  const signOut = useCallback(() => {
    localStorage.removeItem('@OpticalLaboratory:token')
    localStorage.removeItem('@OpticalLaboratory:clientApplication')
    localStorage.removeItem('@OpticalLaboratory:menus')

    setData({} as AuthState)
  }, [])

  return (
    <AuthContext.Provider
      value={{
        setHistory,
        backupHistory,
        clientApplication: data.clientApplication,
        menus: data.menus,
        signIn,
        signOut,
        clientApplicationLogged,
        updateClientApplication
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutProvider')
  }

  return context
}
