import PaymentGatewaysList from '../../pages/Financial/PaymentGateways/List'
import PaymentGatewaysUpdate from '../../pages/Financial/PaymentGateways/Update'
import PaymentGatewaysCreate from '../../pages/Financial/PaymentGateways/Create'
import PaymentGatewaysView from '../../pages/Financial/PaymentGateways/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const PaymentGatewaysRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route
      path="/financial/paymentGateways"
      exact
      component={PaymentGatewaysList}
    />
    <Route
      path="/financial/paymentGateways/create"
      component={PaymentGatewaysCreate}
      exact
    />
    <Route
      path="/financial/paymentGateways/view/:id"
      component={PaymentGatewaysView}
      exact
    />
    <Route
      path="/financial/paymentGateways/update/:id"
      component={PaymentGatewaysUpdate}
      exact
    />
  </CustomSwitch>
)
