import GroupsList from '../../pages/Warehouse/Groups/List'
import GroupsUpdate from '../../pages/Warehouse/Groups/Update'
import GroupsCreate from '../../pages/Warehouse/Groups/Create'
import GroupsView from '../../pages/Warehouse/Groups/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const GroupsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/warehouse/groups" exact component={GroupsList} />
    <Route path="/warehouse/groups/create" component={GroupsCreate} exact />
    <Route path="/warehouse/groups/view/:id" component={GroupsView} exact />
    <Route path="/warehouse/groups/update/:id" component={GroupsUpdate} exact />
  </CustomSwitch>
)
