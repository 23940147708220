import { useCallback, useState } from 'react'
import DataTable from '../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import Container from '../../../../components/Container'
import Form from '../../../../components/Form'
import { Date as DatePicker } from '../../../../components/Form/date'

interface SearchProps {
  start_date: Date
  end_date: Date
}

const ExtractList = (): JSX.Element => {
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const minDate = new Date()
  minDate.setDate(minDate.getDate() - 61)
  const last30Days = new Date()
  last30Days.setDate(last30Days.getDate() - 31)
  const [searchParameters, setSearchParameters] = useState<any[]>([
    {
      start_date: last30Days,
      end_date: new Date(),
      fieldDate: 'downloaded_at'
    }
  ])
  const [clearFilter, setClearFilter] = useState<any>()
  const handleSetFilter = useCallback((data: SearchProps) => {
    if (data.start_date > data.end_date) {
      const startDate = data.start_date
      const endDate = data.end_date
      data.start_date = endDate
      data.end_date = startDate
      setStartDate(endDate)
      setEndDate(startDate)
    }
    setSearchParameters([{ ...data, fieldDate: 'downloaded_at' }])
  }, [])

  const handleClearFilter = useCallback(() => {
    setClearFilter({
      finished: '',
      start_date: '',
      end_date: ''
    })
    setSearchParameters([
      {
        start_date: last30Days,
        end_date: new Date(),
        fieldDate: 'downloaded_at'
      }
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Container pageTitle="Listagem" breadcrumb={breadcrumbList} tools={[]}>
      <Form onSubmit={handleSetFilter} defaultValues={clearFilter}>
        <div className="form">
          <div className="row mb-5">
            <DatePicker
              className="col-md-3"
              name="start_date"
              label="Início"
              selected={startDate}
              onChange={date => setStartDate(date)}
              minDate={minDate}
              maxDate={new Date()}
              controlled
            />
            <DatePicker
              label="Término"
              className="col-md-3"
              name="end_date"
              selected={endDate}
              onChange={date => setEndDate(date)}
              maxDate={new Date()}
              minDate={minDate}
              controlled
            />
            <div className="col-md-5 d-flex justify-content-end align-items-end mb-4">
              <button
                type="button"
                className="btn btn-light-primary"
                onClick={handleClearFilter}
              >
                Limpar
              </button>
            </div>
            <div className="col-md-1 d-flex justify-content-end align-items-end mb-4">
              <button type="submit" className="btn btn-primary">
                Filtrar
              </button>
            </div>
          </div>
        </div>
      </Form>
      <DataTable
        source={nameSource}
        entity={nameEntity}
        format={{ orderBy: '' }}
        notHasChildren
        headers={headers}
        searchParameters={[{ ...searchParameters[0], finished: 'S' }]}
      />
    </Container>
  )
}

export default ExtractList
