import ServicesList from '../../pages/Warehouse/Services/List'
import ServicesUpdate from '../../pages/Warehouse/Services/Update'
import ServicesCreate from '../../pages/Warehouse/Services/Create'
import ServicesView from '../../pages/Warehouse/Services/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const ServicesRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/warehouse/services" exact component={ServicesList} />
    <Route path="/warehouse/services/create" component={ServicesCreate} exact />
    <Route path="/warehouse/services/view/:id" component={ServicesView} exact />
    <Route
      path="/warehouse/services/update/:id"
      component={ServicesUpdate}
      exact
    />
  </CustomSwitch>
)
