import React, { useCallback, useState } from 'react'
import DataTable from '../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import Container from '../../../../components/Container'
import Form, { Select } from '../../../../components/Form'
import { Date } from '../../../../components/Form/date'

const IncomeList = (): JSX.Element => {
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [searchParameters, setSearchParameters] = useState<any[]>([])
  const [clearFilter, setClearFilter] = useState<any>()
  const handleSetFilter = useCallback(data => {
    setSearchParameters([data])
  }, [])

  const handleClearFilter = useCallback(() => {
    setClearFilter({
      finished: '',
      start_date: '',
      end_date: ''
    })
    setStartDate(undefined)
    setEndDate(undefined)
    setSearchParameters([])
  }, [])
  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsList}
    >
      <Form onSubmit={handleSetFilter} defaultValues={clearFilter}>
        <div className="form">
          <div className="row mb-5">
            <Date
              className="col-md-3"
              name="start_date"
              label="Início"
              selected={startDate}
              onChange={date => setStartDate(date)}
              controlled
            />
            <Date
              label="Término"
              className="col-md-3"
              name="end_date"
              selected={endDate}
              onChange={date => setEndDate(date)}
              controlled
            />
            <Select
              className="col-md-2"
              name="finished"
              label="Realizado"
              defaultValue=""
              blank
              options={[
                {
                  name: 'Sim',
                  value: 'S'
                },
                {
                  name: 'Não',
                  value: 'N'
                },
                {
                  name: 'Cancelado',
                  value: 'C'
                }
              ]}
            />
            <div className="col-md-3 d-flex justify-content-end align-items-end mb-4">
              <button
                type="button"
                className="btn btn-light-primary"
                onClick={handleClearFilter}
              >
                Limpar
              </button>
            </div>
            <div className="col-md-1 d-flex justify-content-end align-items-end mb-4">
              <button type="submit" className="btn btn-primary">
                Filtrar
              </button>
            </div>
          </div>
        </div>
      </Form>
      <DataTable
        source={nameSource}
        entity={nameEntity}
        format={{ orderBy: '' }}
        notHasChildren
        headers={headers}
        searchParameters={[{ ...searchParameters[0], operation_type: 'C' }]}
      />
    </Container>
  )
}

export default IncomeList
