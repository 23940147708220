import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../components/Container'
import Tabs from '../../../../components/Tabs'
import Tab from '../../../../components/Tabs/Tab'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { nameActions } from '../domain/info'
import { apiDelete } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'
import ProductTab from '../components/tabs/ProductTab'
import StockTab from '../components/tabs/StockTab'

type Category = {
  id: number
  parent_id: number
  name: string
  price: string
  spherical_start?: number
  spherical_end?: number
  cylindrical_start?: number
  cylindrical_end?: number
  addition_start?: number
  addition_end?: number
  unitType: { name: string }
  parentProductCategory: Category
  tablesPrices: any[]
  stocks: Stock[]
  ncm?: number
  cst?: number
  cfop?: number
  cfop_out_of_state?: number
  created_at: string
  updated_at: string
}

type Table = {
  id: number
  table: {
    name: string
  }
  wholesale_price: string
  unit_price: string
}

type Stock = {
  spherical: string
  cylindrical: string
  sum: number
}

const View: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const [group, setGroup] = useState<Category>()
  const [family, setFamily] = useState<Category>()
  const [stocks, setStocks] = useState<Stock[]>([])
  const [tables, setTables] = useState<Table[]>([])
  const { addToast } = useToast()

  const { disableLoading, activeLoading } = useLoading()
  const loadGroup = useCallback(async (): Promise<void> => {
    activeLoading()
    try {
      const { data } = await api.get<Category>(
        `warehouse/productCategories/view/${id}`
      )
      const group = data
      setGroup(group)
      setFamily(group.parentProductCategory)
      setStocks(group.stocks)
      const tables: Table[] = []
      group.tablesPrices.forEach(table => {
        tables.push({ ...table })
      })
      setTables(tables)
      disableLoading()
    } catch (err) {
      const [, baseUrl, path] = location.pathname.split('/')
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar o Grupo de usuário',
        description:
          'Houve um error ao carregar o grupo, tente novamente mais tarde!'
      })
      if (path.includes('view')) {
        history.push(`/${baseUrl}`)
      } else {
        history.push(`/${baseUrl}/${path}`)
      }
    }
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])

  useEffect(() => {
    loadGroup()
  }, [loadGroup])

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Atributo removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Atributo não removido, pois ainda está sendo usado.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }
  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row mb">
            <div className="">
              <Tabs>
                {[
                  <Tab key={0} title="Cadastro">
                    <div className="mb-5">
                      <div className="row mb-3 mt-8">
                        <div className="col-md-2">
                          <div className="form-group">
                            <label htmlFor="id" className="control-label">
                              Cód.
                            </label>
                            <p className="fw-bolder fs-6 text-gray-800">
                              {group?.id}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="id" className="control-label">
                              Família
                            </label>
                            <p className="fw-bolder fs-6 text-gray-800">
                              {family?.name}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="name" className="control-label">
                              Grupo
                            </label>
                            <p className="fw-bolder fs-6 text-gray-800">
                              {group?.name}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label htmlFor="name" className="control-label">
                              Unidade
                            </label>
                            <p className="fw-bolder fs-6 text-gray-800">
                              {group?.unitType?.name}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label htmlFor="name" className="control-label">
                              Preço de custo
                            </label>
                            <p className="fw-bolder fs-6 text-gray-800">
                              {group?.price}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="created" className="control-label">
                              Grau Esférico
                            </label>
                            <p className="fw-bolder fs-6 text-gray-800">
                              {`${group?.spherical_start}/${group?.spherical_end}`}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="updated" className="control-label">
                              Grau Cilíndrico
                            </label>
                            <p className="fw-bolder fs-6 text-gray-800">
                              {`${group?.cylindrical_start}/${group?.cylindrical_end}`}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="separator my-7" />
                      <div className="row mb-3">
                        {tables.map(table => (
                          <div key={table.id} className="col-md-3">
                            <b>{table.table.name}</b>
                            <p>
                              Preço Unitário: <b>{table.unit_price}</b>
                            </p>
                            <p>
                              Preço Atacado: <b>{table.wholesale_price}</b>
                            </p>
                          </div>
                        ))}
                      </div>
                      <div className="separator my-7" />
                      <div className="row mb-3">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="created" className="control-label">
                              NCM/SH
                            </label>
                            <p className="fw-bolder fs-6 text-gray-800">
                              {group?.ncm}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="updated" className="control-label">
                              CST
                            </label>
                            <p className="fw-bolder fs-6 text-gray-800">
                              {group?.cst}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="updated" className="control-label">
                              CFOP
                            </label>
                            <p className="fw-bolder fs-6 text-gray-800">
                              {group?.cfop}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="updated" className="control-label">
                              CFOP Outro estado
                            </label>
                            <p className="fw-bolder fs-6 text-gray-800">
                              {group?.cfop_out_of_state}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>,
                  <Tab key={1} title="Produtos">
                    <div className="">
                      <ProductTab
                        cylindrical_start={group?.cylindrical_start}
                        cylindrical_end={group?.cylindrical_end}
                        spherical_start={group?.spherical_start}
                        spherical_end={group?.spherical_end}
                        stocks={stocks}
                      />
                    </div>
                  </Tab>,
                  <Tab key={2} title="Entrada de estoque">
                    <div className="">
                      <StockTab
                        cylindrical_start={group?.cylindrical_start}
                        cylindrical_end={group?.cylindrical_end}
                        spherical_start={group?.spherical_start}
                        spherical_end={group?.spherical_end}
                        loadGroup={loadGroup}
                      />
                    </div>
                  </Tab>,
                  <Tab key={3} title="Código de barras"></Tab>
                ]}
              </Tabs>
            </div>
          </div>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${group?.name} ?`}
        onClickCancelButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(group?.id))
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}

export default View
