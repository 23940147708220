export const apiCreate = (): string =>
  '/financial/financialMovimentsTypesGroups/'
export const apiList = (id?: string): string =>
  !id
    ? '/financial/financialMovimentsTypesGroups/'
    : `/financial/financialMovimentsTypesGroups/view/${id}`
export const apiUpdate = (id: string): string =>
  `/financial/financialMovimentsTypesGroups/update/${id}`
export const apiDelete = (id: string): string =>
  `/financial/financialMovimentsTypesGroups/${id}`
