import { useEffect, useState } from 'react'
import Tabs from '../../../../../../components/Tabs'
import Tab from '../../../../../../components/Tabs/Tab'
import buildRange from '../../../../../../utlis/buildRange'
import { Th, Td } from './styles'

type Stock = {
  spherical: string
  cylindrical: string
  sum: number
}
type Props = {
  spherical_start?: number
  spherical_end?: number
  cylindrical_start?: number
  cylindrical_end?: number
  stocks: Stock[]
}
const ProductTab = ({
  spherical_start,
  spherical_end,
  cylindrical_start,
  cylindrical_end,
  stocks
}: Props) => {
  const [sphericalPositive, setSphericalPositive] = useState([])
  const [sphericalNegative, setSphericalNegative] = useState([])
  const [selected, setSelected] = useState({
    cyl: -100,
    sph: -100
  })

  useEffect(() => {
    const range = buildRange(spherical_start, spherical_end)
    const index = range.indexOf('0.00')
    if (index !== -1) {
      const sphericalPositive = range.slice(index + 1, range.length)
      const sphericalNegative = range.slice(0, index + 1).reverse()
      setSphericalPositive(sphericalPositive)
      setSphericalNegative(sphericalNegative)
    }
  }, [spherical_start, spherical_end, cylindrical_start, cylindrical_end])
  return (
    <Tabs>
      {[
        <Tab key={0} title="Grade Negativa (-/-)">
          <table className="table mt-5">
            <thead>
              <tr>
                <th> </th>
                {buildRange(cylindrical_start, cylindrical_end)
                  .reverse()
                  .map(cyl => (
                    <Th
                      key={cyl}
                      className="fw-bold"
                      selected={Number(cyl) === selected.cyl}
                    >
                      {cyl}
                    </Th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {sphericalNegative.map(sph => (
                <>
                  <tr>
                    <Td
                      className="fw-bold"
                      selected={Number(sph) === selected.sph}
                    >
                      {sph}
                    </Td>
                    {buildRange(cylindrical_start, cylindrical_end)
                      .reverse()
                      .map(cyl => (
                        <Td
                          key={`${cyl}-${sph}`}
                          onClick={() =>
                            setSelected({
                              cyl: Number(cyl),
                              sph: Number(sph)
                            })
                          }
                          selected={
                            (Number(sph) >= selected.sph &&
                              Number(cyl) === selected.cyl) ||
                            (Number(sph) === selected.sph &&
                              Number(cyl) >= selected.cyl)
                          }
                          active={
                            Number(sph) === selected.sph &&
                            Number(cyl) === selected.cyl
                          }
                        >
                          {
                            stocks.find(
                              s => s.cylindrical === cyl && s.spherical === sph
                            )?.sum
                          }
                        </Td>
                      ))}
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </Tab>,
        <Tab key={1} title="Grade Positiva (+/-)">
          <table className="table mt-5">
            <thead>
              <tr>
                <th> </th>
                {buildRange(cylindrical_start, cylindrical_end)
                  .reverse()
                  .map(cyl => (
                    <Th
                      key={cyl}
                      className="fw-bold"
                      selected={Number(cyl) === selected.cyl}
                    >
                      {cyl}
                    </Th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {sphericalPositive.map(sph => (
                <>
                  <tr>
                    <Td
                      className="fw-bold"
                      selected={Number(sph) === selected.sph}
                    >
                      {sph}
                    </Td>
                    {buildRange(cylindrical_start, cylindrical_end)
                      .reverse()
                      .map(cyl => (
                        <Td
                          key={`${cyl}-${sph}`}
                          onClick={() =>
                            setSelected({
                              cyl: Number(cyl),
                              sph: Number(sph)
                            })
                          }
                          selected={
                            (Number(sph) <= selected.sph &&
                              Number(cyl) === selected.cyl) ||
                            (Number(sph) === selected.sph &&
                              Number(cyl) >= selected.cyl)
                          }
                          active={
                            Number(sph) === selected.sph &&
                            Number(cyl) === selected.cyl
                          }
                        >
                          {
                            stocks.find(
                              s => s.cylindrical === cyl && s.spherical === sph
                            )?.sum
                          }
                        </Td>
                      ))}
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </Tab>
      ]}
    </Tabs>
  )
}

export default ProductTab
