import React from 'react'
import { CardSimple } from '../../components/CardSimple'
import { PageTitle } from '../../assets/layout/core'
import {
  faArrowAltCircleRight,
  faArrowCircleRight,
  faCubes,
  faDollarSign,
  faInbox
} from '@fortawesome/free-solid-svg-icons'

export const Dashboard: React.FC = () => {
  return (
    <div className="container-fluid">
      <PageTitle>Informações Gerencias - Próxima NF 94</PageTitle>
      <div className="row gy-5 g-xl-8">
        <div className="col-md-4">
          <CardSimple
            primaryColor="danger"
            text="Pedidos aguardando envio"
            number="0"
            footer={{
              link: '/commercial/orders',
              text: 'Mais Detalhes',
              iconSettings: {
                icon: faArrowCircleRight,
                style: {
                  size: 'lg'
                }
              }
            }}
            iconSettings={{
              icon: faInbox
            }}
          />
        </div>
        <div className="col-md-4">
          <CardSimple
            primaryColor="primary"
            text="Pedidos não faturados"
            number="377"
            footer={{
              link: '/commercial/orders',
              text: 'Mais Detalhes',
              iconSettings: {
                icon: faArrowAltCircleRight,
                style: {
                  size: 'lg'
                }
              }
            }}
            iconSettings={{
              icon: faInbox
            }}
          />
        </div>
        <div className="col-md-4">
          <CardSimple
            primaryColor="danger"
            text="Faturas Vencidas"
            number="28"
            footer={{
              link: '/financial/financialMoviments',
              text: 'Mais Detalhes',
              iconSettings: {
                icon: faArrowAltCircleRight,
                style: {
                  size: 'lg'
                }
              }
            }}
            iconSettings={{
              icon: faDollarSign
            }}
          />
        </div>
        <div className="col-md-4">
          <CardSimple
            primaryColor="warning opacity-1"
            text="Faturas em aberta"
            number="0"
            footer={{
              link: '/financial/financialMoviments',
              text: 'Mais Detalhes',
              iconSettings: {
                icon: faArrowAltCircleRight,
                style: {
                  size: 'lg'
                }
              }
            }}
            iconSettings={{
              icon: faDollarSign
            }}
          />
        </div>
        <div className="col-md-4">
          <CardSimple
            primaryColor="success"
            text="Clientes com saldos de produtos"
            number="0"
            footer={{
              link: '/commercial/orders',
              text: 'Mais Detalhes',
              iconSettings: {
                icon: faArrowAltCircleRight,
                style: {
                  size: 'lg'
                }
              }
            }}
            iconSettings={{
              icon: faCubes
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Dashboard
