type TypeActionNameWithTo = {
  name: string
  to: string
  icon: string
}

type PortletTitleCrudType = {
  create: TypeActionNameWithTo
  read: TypeActionNameWithTo
  update: TypeActionNameWithTo
  delete: TypeActionNameWithTo
}

const nameEntity = 'Order'
const nameSource = 'commercial/orders'
const namePageTitle = 'Fornecedores'

const nameActionPageMain: TypeActionNameWithTo = {
  name: 'Fornecedores',
  to: '/commercial/orders',
  icon: ''
}

const nameActions: PortletTitleCrudType = {
  create: {
    name: 'Adicionar',
    to: '/commercial/orders/create/',
    icon: 'fa fa-plus'
  },
  read: {
    name: 'Visualizar',
    to: '/commercial/orders/',
    icon: 'fa fa-list'
  },
  update: {
    name: 'Atualizar',
    to: '/commercial/orders/update/',
    icon: 'fa fa-edit'
  },
  delete: {
    name: 'Remover',
    to: '#!',
    icon: 'fa fa-remove'
  }
}

const frameType = [
  { name: 'Acetato', value: '' },
  { name: 'Fio de Nylon', value: '' },
  { name: 'Metal', value: '' },
  { name: 'Parafusada', value: '' }
]
const shipMethods = [
  { name: 'Retirada na Loja', value: 'Retirada na Loja' },
  { name: 'Motoboy', value: 'Motoboy' },
  { name: 'Correios', value: 'Correios' },
  { name: 'Transportadora', value: 'Transportadora' }
]
const types = [
  { name: 'Venda', value: 'V' },
  { name: 'Venda para Beneficiação', value: 'B' },
  { name: 'Venda para Saldo', value: 'S' }
]
const paymentMethods = [
  { name: 'Crédito', value: 'Crédito' },
  { name: 'Fatura', value: 'Fatura' },
  { name: 'Dinheiro', value: 'Dinheiro' },
  { name: 'Cheque', value: 'Cheque' },
  { name: 'Boleto', value: 'Boleto' },
  { name: 'Transferência Bancária', value: 'Transferência Bancária' }
]

export {
  frameType,
  shipMethods,
  types,
  paymentMethods,
  nameEntity,
  nameSource,
  namePageTitle,
  nameActionPageMain,
  nameActions
}
