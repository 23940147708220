import SalesTablesList from '../../pages/Users/SalesTables/List'
import SalesTablesUpdate from '../../pages/Users/SalesTables/Update'
import SalesTablesCreate from '../../pages/Users/SalesTables/Create'
import SalesTablesView from '../../pages/Users/SalesTables/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const SalesTablesRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/users/salesTables" exact component={SalesTablesList} />
    <Route
      path="/users/salesTables/create"
      component={SalesTablesCreate}
      exact
    />
    <Route
      path="/users/salesTables/view/:id"
      component={SalesTablesView}
      exact
    />
    <Route
      path="/users/salesTables/update/:id"
      component={SalesTablesUpdate}
      exact
    />
  </CustomSwitch>
)
