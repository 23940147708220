import ProvidersList from '../../pages/Commercial/Providers/List'
import ProvidersUpdate from '../../pages/Commercial/Providers/Update'
import ProvidersCreate from '../../pages/Commercial/Providers/Create'
import ProvidersView from '../../pages/Commercial/Providers/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const ProvidersRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/commercial/providers" exact component={ProvidersList} />
    <Route
      path="/commercial/providers/create"
      component={ProvidersCreate}
      exact
    />
    <Route
      path="/commercial/providers/view/:id"
      component={ProvidersView}
      exact
    />
    <Route
      path="/commercial/providers/update/:id"
      component={ProvidersUpdate}
      exact
    />
  </CustomSwitch>
)
