import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'

import Form, { Input, Textarea } from '../../../../../components/Form'
import Button from '../../../../../components/Button'

type FamilyData = {
  name: string
  description: string
}

type TypesFormProps = {
  initialValues?: FamilyData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

export const FormFamilies = ({
  typeForm,
  initialValues
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const [defaultValues, setDefaultValues] = useState<FamilyData>()
  const { activeLoading, disableLoading } = useLoading()

  useEffect(() => {
    if (initialValues) {
      setDefaultValues({ ...initialValues })
    }
  }, [initialValues])

  const onSubmit = async (data: FamilyData) => {
    try {
      if (typeForm === 'create') {
        try {
          const dataCreate = {
            ...data
          }
          activeLoading()
          await api.post(apiCreate(), dataCreate)
          disableLoading()
          addToast({
            type: 'success',
            title: 'Registro criado',
            description: 'Registro criado com sucesso'
          })
          history.push(nameActions.read.to)
        } catch (error) {
          addToast({
            type: 'error',
            title: 'Erro ao adicionar o registro',
            description:
              'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
          })
          disableLoading()
        }
      } else {
        const dataUpdate = {
          ...data
        }
        const id = initialValues?.idUpdate

        try {
          activeLoading()
          await api.put(apiUpdate(String(id)), dataUpdate)
          disableLoading()
          history.push(nameActions.read.to)
          addToast({
            type: 'success',
            title: 'Registro atualizado',
            description: 'Registro alterado com sucesso'
          })
        } catch (error) {
          history.push(nameActions.read.to)
          addToast({
            type: 'error',
            title: 'Erro ao atualizar o registro',
            description:
              'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
          })
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
      }
    }
  }

  return (
    <div className="card mb-5 mb-xl-10">
      <Form onSubmit={onSubmit} defaultValues={defaultValues}>
        <div className="row">
          <Input
            label="Nome"
            name="name"
            className="col-md-9"
            rules={{ required: true }}
          />
          <Textarea
            label="Descrição"
            rows={7}
            name="description"
            rules={{ required: true }}
          />
        </div>
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <Button type="submit" className="btn btn-primary">
            Salvar
          </Button>
        </div>
      </Form>
    </div>
  )
}
