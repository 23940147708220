export const headers = [
  { name: 'Título', field: 'title', sortable: true },
  {
    name: 'Anexo',
    field: 'attachment',
    sortable: false,
    download: {
      fileName: 'attachment',
      fileUrl: 'attachment_url'
    }
  },
  {
    name: 'Ativo',
    field: 'active',
    sortable: false
  },
  { name: 'Cadastrado por', field: 'clientApplication.name', sortable: false },
  { name: 'Cadastrado em', field: 'created_at', sortable: false },
  { name: 'Ações', field: 'actions', sortable: false }
]
