import ClientsApplicationsUsersList from '../../pages/Users/ClientsApplicationsUsers/List'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const ClientsApplicationsUsersRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route
      path="/users/clientsApplicationsUsers"
      exact
      component={ClientsApplicationsUsersList}
    />
  </CustomSwitch>
)
