import React from 'react'
// import Container from '../../../../../components/Container'
// import { FormUser } from '../components/Form'
// import { nameActions } from '../domain/info'
// import { breadcrumbCreate } from '../domain/breadcrumb'
// import { toolsCreate } from '../domain/tools/create'

const UserCreate = (): JSX.Element => (
  // <Container
  //   pageTitle={nameActions.create.name}
  //   breadcrumb={breadcrumbCreate}
  //   tools={toolsCreate}
  // >
  //   <FormUser typeForm="create" />
  // </Container>
  <div></div>
)

export default UserCreate
