import Container from '../../../../components/Container'
import { FormCategory } from '../components/Form'
import { nameActions } from '../domain/info'
import { breadcrumbCreate } from '../domain/breadcrumb'
import { toolsCreate } from '../domain/tools/create'

const FinancialCategoryCreate = (): JSX.Element => (
  <Container
    pageTitle={nameActions.create.name}
    breadcrumb={breadcrumbCreate}
    tools={toolsCreate}
  >
    <div className="form-body">
      <FormCategory typeForm="create" />
    </div>
  </Container>
)

export default FinancialCategoryCreate
