import FinancialMovimentsTypesGroupsList from '../../pages/Financial/FinancialMovimentsTypesGroups/List'
import FinancialMovimentsTypesGroupsUpdate from '../../pages/Financial/FinancialMovimentsTypesGroups/Update'
import FinancialMovimentsTypesGroupsCreate from '../../pages/Financial/FinancialMovimentsTypesGroups/Create'
import FinancialMovimentsTypesGroupsView from '../../pages/Financial/FinancialMovimentsTypesGroups/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const FinancialMovimentsTypesGroupsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route
      path="/financial/financialMovimentsTypesGroups"
      exact
      component={FinancialMovimentsTypesGroupsList}
    />
    <Route
      path="/financial/financialMovimentsTypesGroups/create"
      component={FinancialMovimentsTypesGroupsCreate}
      exact
    />
    <Route
      path="/financial/financialMovimentsTypesGroups/view/:id"
      component={FinancialMovimentsTypesGroupsView}
      exact
    />
    <Route
      path="/financial/financialMovimentsTypesGroups/update/:id"
      component={FinancialMovimentsTypesGroupsUpdate}
      exact
    />
  </CustomSwitch>
)
