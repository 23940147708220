import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'

import Form, { Input, Select } from '../../../../../components/Form'
import { FormContent } from './styles'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type CredentialsProps = {
  name: string
  label: string
  type: string
}
type PaymentGatewaysData = {
  paymentModule: {
    id: number
  }
  payment_module_id: number
  type: string
}
type PaymentModulesData = {
  id: number
  module: string
  name: string
  type: string
  fields: any
  active: boolean
}
type PaymentGetwayForm = {
  credentials: any
  payment_module_id: number
  type: string
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: PaymentGatewaysData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

export const FormPaymentGateways = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] = useState<PaymentGatewaysData>()
  const [id, setId] = useState<number>()
  const [files, setFiles] = useState<File[]>([])
  const [paymentModulesType, setPaymentModulesType] = useState<
    PaymentModulesData[]
  >([])
  const [paymentModulesFields, setPaymentModulesFields] = useState<
    CredentialsProps[]
  >([])
  const [paymentModulesName, setPaymentModulesName] = useState<
    { name: string; value: number }[]
  >([])

  const handlePaymentModule = useCallback(
    (paymentModuleId: string | number) => {
      const id = Number(paymentModuleId)
      if (id) {
        const findPaymentModule = paymentModulesType.find(
          paymentModule => paymentModule.id === id
        )
        const fields: any = Object.values(findPaymentModule.fields)
        setPaymentModulesFields(fields)
      }
    },
    [paymentModulesType]
  )

  useEffect(() => {
    if (initialValues) {
      setId(initialValues.idUpdate)
      setDefaultValues({
        ...initialValues
      })
    }
  }, [initialValues])

  const { activeLoading, disableLoading } = useLoading()

  const onSubmitForm = async (data: PaymentGetwayForm) => {
    const copyCredentials: any = {}
    data.payment_module_id = Number(data.payment_module_id)
    const entries = Object.entries(data.credentials)
    for (const item of entries) {
      if (item) {
        const key = item[0]
        const value: any = item[1]
        if (value && typeof value === 'string') {
          copyCredentials[key] = value
        }
        if (value && typeof value !== 'string') {
          copyCredentials[key] = await value[0].text()
        }
      }
    }
    data.credentials = copyCredentials
    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose, idParent } = isOpenInModal
          const dataCreate = {
            ...data,
            parent_id: idParent
          }
          activeLoading()
          try {
            await api.post(apiCreate(), dataCreate)
            handleOnClose()
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
          } catch (error: any) {
            if (error.response.status === 401) {
              addToast({
                type: 'error',
                title: 'Erro ao adicionar o registro',
                description:
                  'Credenciais incorretas, por favor, tente novamente.'
              })
            } else {
              addToast({
                type: 'error',
                title: 'Erro ao adicionar o registro',
                description:
                  'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
              })
            }
            handleOnClose()
            disableLoading()
            updateDataTable()
          }
        } else {
          try {
            const dataCreate = {
              ...data
            }
            activeLoading()
            await api.post(apiCreate(), dataCreate)
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
            history.push(nameActions.read.to)
          } catch (error: any) {
            if (error.response.status === 401) {
              addToast({
                type: 'error',
                title: 'Erro ao adicionar o registro',
                description:
                  'Credenciais incorretas, por favor, tente novamente.'
              })
            } else {
              addToast({
                type: 'error',
                title: 'Erro ao adicionar o registro',
                description:
                  'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
              })
            }
            disableLoading()
            updateDataTable()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataUpdate = {
            ...data
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error: any) {
            disableLoading()
            handleOnClose()
            if (error.response.status === 401) {
              addToast({
                type: 'error',
                title: 'Erro ao adicionar o registro',
                description:
                  'Credenciais incorretas, por favor, tente novamente.'
              })
            } else {
              addToast({
                type: 'error',
                title: 'Erro ao atualizar o registro',
                description:
                  'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
              })
            }
          }
        } else {
          const dataUpdate = {
            ...data
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error: any) {
            history.push(nameActions.read.to)
            if (error.response.status === 401) {
              addToast({
                type: 'error',
                title: 'Erro ao adicionar o registro',
                description:
                  'Credenciais incorretas, por favor, tente novamente.'
              })
            } else {
              addToast({
                type: 'error',
                title: 'Erro ao atualizar o registro',
                description:
                  'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
              })
            }
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }

  const loadPaymentModules = useCallback(async () => {
    try {
      const response = await api.get('/financial/paymentModules')
      setPaymentModulesType(
        response.data.filter((item: PaymentModulesData) => item.active)
      )
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao carregar o módulos de pagamento',
        description:
          'Ocorreu um erro ao carregar os módulos de pagamento, por favor, tente novamente.'
      })
    }
  }, [addToast])

  const handlePaymentType = useCallback(
    (type: string) => {
      const filterPaymentModules = paymentModulesType.filter(
        paymentModule => paymentModule.type === type
      )
      setPaymentModulesName(
        filterPaymentModules.map(
          paymentModule =>
            paymentModule && {
              name: paymentModule.name,
              value: paymentModule.id
            }
        )
      )
    },
    [paymentModulesType]
  )

  useEffect(() => {
    loadPaymentModules()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (paymentModulesType.length) {
      setPaymentModulesName(
        paymentModulesType.map(
          paymentModule =>
            paymentModule && {
              name: paymentModule.name,
              value: paymentModule.id
            }
        )
      )
      handlePaymentModule(initialValues?.payment_module_id)
    }
  }, [
    handlePaymentModule,
    initialValues?.payment_module_id,
    paymentModulesType
  ])

  function onChangeFileHandler(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) return
    const file = event.target.files[0]
    setFiles([
      ...files,
      {
        ...file,
        name: event.target.name
      }
    ])
  }

  return (
    <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
      <>
        <div className="card mb-5 mb-xl-10">
          <FormContent modal={!!isOpenInModal} className="form">
            <div>
              <Select
                name="type"
                label="Tipo"
                rules={{ required: true }}
                options={paymentModulesType?.map(
                  ({ type }) =>
                    type && {
                      name: type,
                      value: type
                    }
                )}
                blank
                defaultValue=""
                onChange={event => handlePaymentType(event.target.value)}
              />
              <Select
                name="payment_module_id"
                label="Módulo"
                rules={{ required: true }}
                options={paymentModulesName}
                blank
                defaultValue=""
                onChange={event => handlePaymentModule(event.target.value)}
              />
              {paymentModulesFields.map(field =>
                field.type === 'file' ? (
                  <Input
                    key={Math.random()}
                    name={field.name}
                    label={field.label}
                    type="file"
                    onChange={onChangeFileHandler}
                  />
                ) : (
                  <Input
                    key={Math.random()}
                    name={field.name}
                    label={field.label}
                    rules={{ required: true }}
                  />
                )
              )}
            </div>
          </FormContent>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </>
    </Form>
  )
}
