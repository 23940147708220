import React, { useCallback, useEffect, useState } from 'react'
import Container from '../../../../components/Container'
import DataTable from '../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import api from '../../../../services/api'
import { useUpdateDataTable } from '../../../../hooks/dataTable'
import { Alert } from '../../../../components/Alert'
import Form, { Select } from '../../../../components/Form'
import { Date } from '../../../../components/Form/date'
import { useToast } from '../../../../hooks/toast'

type Client = {
  id: number
  company_name: string
}
type FilterData = {
  id: number
  company_name: string
}
const List = (): JSX.Element => {
  const { updateDataTable } = useUpdateDataTable()
  const { addToast } = useToast()
  const [alert, setAlert] = useState(false)
  const [order, setOrder] = useState<any>()
  const [clients, setClients] = useState<Client[]>([])
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [searchParameters, setSearchParameters] = useState<any[]>([])
  const [clearFilter, setClearFilter] = useState<any>()

  useEffect(() => {
    ;(async () => {
      const { data } = await api.get('commercial/clients')
      setClients(data)
    })()
  }, [])

  async function handleFinishOrderButton() {
    setAlert(false)
    await api.post(`commercial/orders/finishOrder/${order?.id}`, {
      status: 'Finished'
    })
    updateDataTable()
  }
  async function onSubmitFilter(data: FilterData) {
    setSearchParameters([data])
  }
  const handleClearFilter = useCallback(() => {
    setClearFilter({
      client_id: '',
      start_date: '',
      end_date: '',
      type: '',
      status: ''
    })
    setStartDate(undefined)
    setEndDate(undefined)
    setSearchParameters([])
  }, [])
  return (
    <>
      <Container
        pageTitle="Listagem"
        breadcrumb={breadcrumbList}
        tools={toolsList}
      >
        <Form onSubmit={onSubmitFilter} defaultValues={clearFilter}>
          <div className="row">
            <Select
              className="col-md-6"
              name="client_id"
              options={clients.map(client => ({
                name: client.company_name,
                value: client.id
              }))}
              blank
              defaultValue={''}
            />
          </div>
          <div className="row">
            <Date
              label="Início"
              name="start_date"
              className="col-md-3"
              selected={startDate}
              onChange={date => setStartDate(date)}
              controlled
            />
            <Date
              label="Término"
              name="end_date"
              className="col-md-3"
              selected={endDate}
              onChange={date => setEndDate(date)}
              controlled
            />
          </div>
          <div className="row">
            <Select
              className="col-md-3"
              name="type"
              label="Tipo"
              options={[
                { name: 'Venda', value: 'V' },
                { name: 'Venda para Beneficiação', value: 'B' },
                { name: 'Venda para Saldo', value: 'S' }
              ]}
              blank
              defaultValue={''}
            />
            <Select
              className="col-md-3"
              name="status"
              label="Status"
              options={[
                { name: 'Realizado', value: 'Accomplished' },
                { name: 'Separado', value: 'Separated' },
                { name: 'Enviado', value: 'Sent' },
                { name: 'Em Aberto', value: 'Open' },
                { name: 'Finalizado', value: 'Finished' }
              ]}
              blank
              defaultValue={''}
            />
          </div>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button
              type="button"
              className="btn btn-light-primary"
              onClick={handleClearFilter}
            >
              Limpar
            </button>
            <button type="submit" className="btn btn-primary  ms-5">
              Filtrar
            </button>
          </div>
        </Form>
        <DataTable
          source={nameSource}
          entity={nameEntity}
          format={{ orderBy: 'id' }}
          notHasChildren
          headers={headers}
          searchParameters={searchParameters}
          actions={[
            {
              name: 'Visualizar',
              title: 'Visualizar',
              spanIcon: 'fa fa-search',
              linkTo: order => `/commercial/orders/view/${order.id}`
            },
            {
              name: 'Excluir',
              title: 'Excluir',
              spanIcon: 'fa fa-close',
              onClick: async order => {
                try {
                  await api.delete(`/commercial/orders/${order.id}`)
                  updateDataTable()
                  addToast({
                    type: 'success',
                    title: 'Atributo removido com sucesso.'
                  })
                } catch (error) {
                  addToast({
                    type: 'error',
                    title: 'Atributo não removido, pois ainda está sendo usado.'
                  })
                }
              },
              style: { margin: '0 5px' }
            },
            {
              name: 'Finalizar',
              title: 'Finalizar',
              htmlIcon: <span className="fa fa-check"></span>,
              hasIcon: order => order.status !== 4,
              onClick: async order => {
                setAlert(true)
                setOrder(order)
              }
            }
          ]}
        />
      </Container>
      <Alert
        isActive={alert}
        onClickConfirmButton={handleFinishOrderButton}
        onClickCancelButton={() => setAlert(false)}
        message={`Confirma a baixa do pedido ${order?.id} da OS ${order?.os}?`}
      />
    </>
  )
}

export default List
