export const headers = [
  { name: 'Baixa', field: 'checkbox', sortable: true },
  {
    name: 'Fornecedor',
    field: 'provider.company_social_name',
    sortable: false
  },
  {
    name: 'Categoria',
    field: 'financialCategory.name',
    sortable: false
  },
  {
    name: 'Sub Categoria',
    field: 'financialSubCategory.name',
    sortable: false
  },
  { name: 'Vencimento', field: 'due_date', sortable: false },
  {
    name: 'Valor',
    field: 'value',
    sortable: false,

    hasTypes: {
      types: {
        monetary: {
          symbol: '',
          decimal: ',',
          separator: '.'
        }
      }
    }
  },
  {
    name: 'Pago',
    field: 'finished',
    sortable: false,
    hasTypes: {
      types: {
        S: 'Sim',
        N: 'Não',
        C: 'Cancelado'
      }
    }
  },
  { name: 'Gerado em', field: 'created_at', sortable: false },
  { name: 'Ações', field: 'actions', sortable: false }
]
