import OrdersList from '../../pages/Commercial/Orders/List'
import OrdersUpdate from '../../pages/Commercial/Orders/Update'
import OrdersCreate from '../../pages/Commercial/Orders/Create'
import OrdersView from '../../pages/Commercial/Orders/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const OrdersRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/commercial/orders" exact component={OrdersList} />
    <Route path="/commercial/orders/create" component={OrdersCreate} exact />
    <Route
      path="/commercial/orders/create/:client_id"
      component={OrdersCreate}
      exact
    />
    <Route path="/commercial/orders/view/:id" component={OrdersView} exact />
    <Route
      path="/commercial/orders/update/:id"
      component={OrdersUpdate}
      exact
    />
  </CustomSwitch>
)
