import { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Container from '../../../../components/Container'
import { useLoading } from '../../../../hooks/loading'
import api from '../../../../services/api'
import { FormFiscalSettings } from '../components/Form'
import { apiList } from '../domain/api'
import { nameActions } from '../domain/info'
import { breadcrumbUpdate } from '../domain/breadcrumb'
import { toolsUpdate } from '../domain/tools'
import { useToast } from '../../../../hooks/toast'

const FiscalSettingUpdate = (): JSX.Element => {
  const { addToast } = useToast()
  const location = useLocation()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { activeLoading, disableLoading } = useLoading()
  const [fiscalSettingItem, setFiscalSettingItem] = useState<{
    id: string
    company_name: string
    cnpj: string
    city_registration: string
    state_registration?: string
    address: string
    nfse_env?: boolean
    nfse_rps_number?: number
    certified_file?: string
    certified_validate?: string
    certified_password: string
    nf_emission_due?: number
    dir?: number
    invoice_email_copy?: string
    active: string
    street?: string
    number?: string
    complement?: string
    district?: string
    city?: string
    state?: string
    zip_code?: string
    phone?: string
  }>()
  useEffect(() => {
    async function loadData() {
      activeLoading()
      try {
        const response = await api.get(apiList(id))
        const { data } = response
        setFiscalSettingItem({
          ...data,
          nfse_env: data.nfse_env ? '1' : '0',
          certified_validate: String(data.certified_validate).slice(0, 10)
        })
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar a configuração',
          description:
            'Houve um error ao carregar a configuração, tente novamente mais tarde!'
        })
        if (path.includes('update')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadData()
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])

  return (
    <Container
      pageTitle={nameActions.update.name}
      breadcrumb={breadcrumbUpdate}
      tools={toolsUpdate}
    >
      <FormFiscalSettings
        typeForm="update"
        initialValues={{
          idUpdate: Number(fiscalSettingItem?.id),
          ...fiscalSettingItem
        }}
      />
    </Container>
  )
}

export default FiscalSettingUpdate
