export const headers = [
  { name: 'Nome', field: 'company_name', sortable: true },
  { name: 'CNPJ', field: 'cnpj', sortable: false },
  { name: 'Ins. Estadual', field: 'state_registration', sortable: false },
  {
    name: 'Validade Certificado',
    field: 'certified_validate',
    sortable: false
  },
  { name: 'Ações', field: 'actions', sortable: false }
]
