import BankAccountsList from '../../pages/Financial/BankAccounts/List'
import BankAccountsUpdate from '../../pages/Financial/BankAccounts/Update'
import BankAccountsCreate from '../../pages/Financial/BankAccounts/Create'
import BankAccountsView from '../../pages/Financial/BankAccounts/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const BankAccountsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/financial/bankAccounts" exact component={BankAccountsList} />
    <Route
      path="/financial/bankAccounts/create"
      component={BankAccountsCreate}
      exact
    />
    <Route
      path="/financial/bankAccounts/view/:id"
      component={BankAccountsView}
      exact
    />
    <Route
      path="/financial/bankAccounts/update/:id"
      component={BankAccountsUpdate}
      exact
    />
  </CustomSwitch>
)
