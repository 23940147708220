/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../components/Container'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { nameActions } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'
import { cnpjMask, phoneMask } from '../../../../utlis/mask'

interface ClientData {
  id: number
  street: string
  district: string
  city: string
  state: string
  city_registration: string
  state_registration: string
  ibge: string
  company_social_name: number
  company_name: string
  cnpj: string
  zip_code: string
  created_at: string
  updated_at: string
  email: string
  phone_1: string
  phone_2: string
  mobile: string
  note: string
  shipment_method: string
  payment_method: string
  payment_day: string
  active: string
  table_id: number
  table: {
    name: string
  }
}

const ClientView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const [client, setClient] = useState<ClientData | null>(null)
  const { addToast } = useToast()
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })
  const convertTypesPayment: any = {
    F: 'Fatura',
    D: 'Dinheiro',
    B: 'Boleto'
  }
  const convertTypesShipment: any = {
    L: 'Retirada na Loja',
    M: 'Moto Boy'
  }

  const { disableLoading, activeLoading } = useLoading()

  useEffect(() => {
    async function loadClient(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<ClientData>(apiList(id))
        const { data } = response
        setClient(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o cliente',
          description:
            'Houve um error ao carregar o cliente, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadClient()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoading, addToast, disableLoading, history, location.pathname])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
    addToast({
      type: 'info',
      title: 'Operação cancelada.'
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Cliente removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title: 'Cliente não removido, pois ainda está sendo usado.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Cliente removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Cliente não removido, pois ainda está sendo usado.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }
  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row mb-5">
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="id"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Cód.
                </label>
                <p className="fw-bolder fs-6 text-gray-800">{client?.id}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="table_id"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Tabela
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {client?.table?.name}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  htmlFor="company_name"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Razão Social
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {client?.company_name}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="city_registration"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Inscrição Municipal
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {client?.city_registration}
                </p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="state_registration"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Inscrição Estadual
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {client?.state_registration}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  htmlFor="company_social_name"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Nome Fantasia
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {client?.company_social_name}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="Cnpj"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Cnpj
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {cnpjMask(client?.cnpj || '')}
                </p>
              </div>
            </div>
          </div>
          <div className="separator my-5" />
          <div className="row mb-5">
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="street"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Logradouro
                </label>
                <p className="fw-bolder fs-6 text-gray-800">{client?.street}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="district"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Bairro
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {client?.district}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="city"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Cidade
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {client?.city} | {client?.state}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="zip_code"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  CEP
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {client?.zip_code}
                </p>
              </div>
            </div>
          </div>
          <div className="separator my-5" />

          <div className="row mb-5">
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="phone_1"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Telefone 1
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {phoneMask(client?.phone_1 || '')}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="phone_2"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Telefone 2
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {phoneMask(client?.phone_2 || '')}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="mobile"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Celular
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {phoneMask(client?.mobile || '')}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="email"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  E-mail
                </label>
                <p className="fw-bolder fs-6 text-gray-800">{client?.email}</p>
              </div>
            </div>
          </div>
          <div className="separator my-5" />
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="shipment_method"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Forma de Entrega
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {convertTypesShipment[client?.shipment_method]}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="payment_method"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Forma de Pagamento
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {convertTypesPayment[client?.payment_method]}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="payment_day"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Dia Vencimento Fatura
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {client?.payment_day}
                </p>
              </div>
            </div>
          </div>
          <div className="separator my-5" />
          <div className="row mb-5">
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="active"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Ativo
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {client?.active === 'S' ? 'Sim' : 'Não'}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="created"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Cadastrado em
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {client?.created_at}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="updated"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Atualizado em
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {client?.updated_at}
                </p>
              </div>
            </div>
          </div>
          <div className="separator my-5" />
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label
                  htmlFor="note"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Observações
                </label>
                <p className="fw-bolder fs-6 text-gray-800">{client?.note}</p>
              </div>
            </div>
          </div>
          <p className="fw-bolder fs-6 text-gray-800">&nbsp;</p>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${alert.name} ?`}
        onClickCancelButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${client?.company_social_name} ?`}
        onClickCancelButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(client?.id))
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}

export default ClientView
