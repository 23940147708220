import FiscalSettingsList from '../../pages/Financial/FiscalSettings/List'
import FiscalSettingsUpdate from '../../pages/Financial/FiscalSettings/Update'
import FiscalSettingsCreate from '../../pages/Financial/FiscalSettings/Create'
import FiscalSettingsView from '../../pages/Financial/FiscalSettings/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const FiscalSettingsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route
      path="/financial/fiscalSettings"
      exact
      component={FiscalSettingsList}
    />
    <Route
      path="/financial/fiscalSettings/create"
      component={FiscalSettingsCreate}
      exact
    />
    <Route
      path="/financial/fiscalSettings/view/:id"
      component={FiscalSettingsView}
      exact
    />
    <Route
      path="/financial/fiscalSettings/update/:id"
      component={FiscalSettingsUpdate}
      exact
    />
  </CustomSwitch>
)
