import { useEffect, useRef, useState } from 'react'
import { Alert } from '../../../../../../components/Alert'
import Button from '../../../../../../components/Button'
import Form, { Input } from '../../../../../../components/Form'
import Tabs from '../../../../../../components/Tabs'
import Tab from '../../../../../../components/Tabs/Tab'
import { useLoading } from '../../../../../../hooks/loading'
import { useToast } from '../../../../../../hooks/toast'
import api from '../../../../../../services/api'
import buildRange from '../../../../../../utlis/buildRange'
import { Td, Th } from './styles'

type Props = {
  spherical_start?: number
  spherical_end?: number
  cylindrical_start?: number
  cylindrical_end?: number
  loadGroup: () => Promise<void>
}
const StockTab = ({
  spherical_start = 0,
  spherical_end = 0,
  cylindrical_start = 0,
  cylindrical_end = 0,
  loadGroup
}: Props) => {
  const { addToast } = useToast()
  const { disableLoading, activeLoading } = useLoading()

  const [sphericalPositive, setSphericalPositive] = useState([])
  const [sphericalNegative, setSphericalNegative] = useState([])
  const [defaultValues, setDefaultValues] = useState(false)
  const [alert, setAlert] = useState(false)
  const [data, setData] = useState()
  const ref = useRef(null)
  const ref2 = useRef(null)
  const [selected, setSelected] = useState({
    cyl: -100,
    sph: -100
  })

  /*   useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current || ref2.current) {
        if (
          !ref.current.contains(
            (event.target as Node) ||
              !ref2.current.contains(event.target as Node)
          )
        ) {
          setSelected({ cyl: -100, sph: -100 })
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })
 */
  useEffect(() => {
    const range = buildRange(spherical_start, spherical_end)
    const index = range.indexOf('0.00')
    if (index !== -1) {
      const sphericalPositive = range.slice(index + 1, range.length)
      const sphericalNegative = range.slice(0, index + 1).reverse()
      setSphericalPositive(sphericalPositive)
      setSphericalNegative(sphericalNegative)
    }
  }, [spherical_start, spherical_end, cylindrical_start, cylindrical_end])

  async function handlerOnClickButtonConfirm() {
    activeLoading()
    const result: any = []
    Object.entries(data).forEach(([index, value]) => {
      Object.entries(value).forEach(([index2, value2]) => {
        if (value2) {
          result.push({
            spherical: index.replace('z', '.'),
            cylindrical: index2.replace('z', '.'),
            value: value2
          })
        }
      })
    })
    try {
      setAlert(false)
      await api.post('warehouse/stockMoviments/addStocks', { stocks: result })
      addToast({
        type: 'success',
        title: 'Registro criado',
        description: 'Registro criado com sucesso'
      })
      loadGroup()
      disableLoading()
      setDefaultValues(true)
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao adicionar o registro',
        description:
          'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
      })
      setAlert(false)
      disableLoading()
    }
  }
  async function onSubmit(data: any): Promise<void> {
    setData(data)
    setAlert(true)
  }
  return (
    <Form onSubmit={onSubmit} setReset={defaultValues}>
      <Tabs>
        {[
          <Tab key={9} title="Grade Negativa (-/-)">
            <table className="table mt-5" ref={ref}>
              <thead>
                <tr>
                  <th> </th>
                  {buildRange(cylindrical_start, cylindrical_end)
                    .reverse()
                    .map(cyl => (
                      <Th
                        key={cyl}
                        className="fw-bold"
                        selected={Number(cyl) === selected.cyl}
                      >
                        {cyl}
                      </Th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {sphericalNegative.map((sph: string) => (
                  <>
                    <tr>
                      <Td
                        className="fw-bold"
                        selected={Number(sph) === selected.sph}
                      >
                        {sph}
                      </Td>
                      {buildRange(cylindrical_start, cylindrical_end)
                        .reverse()
                        .map((cyl: string) => {
                          const key = `${sph.replace('.', 'z')}.${cyl.replace(
                            '.',
                            'z'
                          )}`
                          return (
                            <Td
                              key={`${cyl}-${sph}`}
                              className="fw-bold"
                              onClick={() =>
                                setSelected({
                                  cyl: Number(cyl),
                                  sph: Number(sph)
                                })
                              }
                              selected={
                                (Number(sph) >= selected.sph &&
                                  Number(cyl) === selected.cyl) ||
                                (Number(sph) === selected.sph &&
                                  Number(cyl) >= selected.cyl)
                              }
                              active={
                                Number(sph) === selected.sph &&
                                Number(cyl) === selected.cyl
                              }
                            >
                              <Input name={key} />
                            </Td>
                          )
                        })}
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </Tab>,
          <Tab key={8} title="Grade Positiva (+/-)">
            <table className="table mt-5" ref={ref2}>
              <thead>
                <tr>
                  <th> </th>
                  {buildRange(cylindrical_start, cylindrical_end)
                    .reverse()
                    .map(cyl => (
                      <Th
                        key={cyl}
                        className="fw-bold"
                        selected={Number(cyl) === selected.cyl}
                      >
                        {cyl}
                      </Th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {sphericalPositive.map((sph: string) => (
                  <>
                    <tr>
                      <Td
                        className="fw-bold"
                        selected={Number(sph) === selected.sph}
                      >
                        {sph}
                      </Td>
                      {buildRange(cylindrical_start, cylindrical_end)
                        .reverse()
                        .map((cyl: string) => {
                          const key = `${sph.replace('.', 'z')}.${cyl.replace(
                            '.',
                            'z'
                          )}`
                          return (
                            <Td
                              key={`${cyl}-${sph}`}
                              className="fw-bold"
                              onClick={() =>
                                setSelected({
                                  cyl: Number(cyl),
                                  sph: Number(sph)
                                })
                              }
                              selected={
                                (Number(sph) <= selected.sph &&
                                  Number(cyl) === selected.cyl) ||
                                (Number(sph) === selected.sph &&
                                  Number(cyl) >= selected.cyl)
                              }
                              active={
                                Number(sph) === selected.sph &&
                                Number(cyl) === selected.cyl
                              }
                            >
                              <Input name={key} />
                            </Td>
                          )
                        })}
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </Tab>
        ]}
      </Tabs>
      <div className="card-footer d-flex justify-content-end py-6 px-9">
        <Button type="submit" className="btn btn-primary">
          Salvar
        </Button>
      </div>
      <Alert
        message={'Tem certeza que deseja adicionar o stock?'}
        onClickCancelButton={() => setAlert(false)}
        onClickConfirmButton={handlerOnClickButtonConfirm}
        isActive={alert}
      />
    </Form>
  )
}

export default StockTab
