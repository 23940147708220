import { HANDLE_ERROS_MESSAGE } from 'common/constants'
import { ToastMessage } from 'hooks/toast'

export const handleErrorMessage = (error: {
  response: { data: { message?: string } }
}): Omit<ToastMessage, 'id'> => {
  let description =
    'Ocorreu um erro ao processar sua solicitação, por favor, tente novamente.'
  const message = error?.response?.data?.message
  if (message) {
    const findError = HANDLE_ERROS_MESSAGE.find(error =>
      message.toLowerCase().includes(error.error.toLowerCase())
    )
    description = findError?.message || message
  }
  return {
    type: 'error',
    title: 'Erro ao Processar Solicitação',
    description
  }
}
