export const headers = [
  { name: 'Nome Fantasia', field: 'company_social_name', sortable: true },
  { name: 'CNPJ', field: 'cnpj', sortable: false },
  { name: 'Telefone', field: 'phone_1', sortable: false },
  {
    name: 'Ativo',
    field: 'active',
    sortable: false,

    hasTypes: {
      types: {
        S: 'Sim',
        N: 'Não'
      }
    }
  },
  { name: 'Cadastrado em', field: 'created_at', sortable: false },
  { name: 'Ações', field: 'actions', sortable: false }
]
