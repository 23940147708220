import { ToolsContainerProps } from '../../../../../../components/Container'
import api from '../../../../../../services/api'
import { IncomeData } from '../../../View'
import { nameActions } from '../../info'
export const toolsViewCreate = (): ToolsContainerProps => ({
  name: 'Adicionar',
  to: nameActions.create.to,
  icon: nameActions.create.icon,
  color: 'btn-light-success'
})

export const toolsViewList = (): ToolsContainerProps => ({
  name: 'Listar',
  to: nameActions.read.to,
  icon: nameActions.read.icon
})

export const toolsViewUpdate = (id: string): ToolsContainerProps => ({
  name: 'Editar',
  to: `${nameActions.update.to}${id}`,
  icon: nameActions.update.icon,
  color: 'btn-light-warning'
})

export const toolsViewDelete = (
  handleOnClick: (pamams: any) => void
): ToolsContainerProps => ({
  name: 'Remover',
  to: nameActions.delete.to,
  icon: nameActions.delete.icon,
  color: 'btn-light-danger',
  handleOnClick
})

export const toolsViewPaymentMethod = (
  data: IncomeData
): ToolsContainerProps => {
  if (!data) return

  const { payment_method, id, client, financialMovimentsPayments } = data
  if (payment_method === 'B') {
    return {
      name: 'Boleto',
      to: '#',
      icon: 'fa fa-barcode',
      handleOnClick: async () => {
        try {
          const response = await api.get(
            `/financial/financialMovimentsPayments/${id}`
          )
          const clientName = client.company_social_name.replaceAll(' ', '-')
          let ourNumber
          if (financialMovimentsPayments?.length) {
            ourNumber = `-${financialMovimentsPayments[0].document_number}`
          }
          const linkSource = `data:application/pdf;base64,${response.data.pdf}`
          const downloadLink = document.createElement('a')
          const fileName = `${clientName}${ourNumber || ''}.pdf`
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        } catch (error) {}
      }
    }
  }
}

export const toolsViewChangeDueDate = (
  data: IncomeData,
  handleClick: () => void
) => {
  if (!data) return
  const { financialMovimentsPayments, finished } = data
  if (
    finished === 'N' ||
    financialMovimentsPayments[0]?.situation === 'Awaiting payment'
  ) {
    return {
      name: 'Alterar Vencimento',
      to: '#',
      icon: 'fa fa-calendar',
      handleOnClick: handleClick
    }
  }
}
export const toolsViewManualDownload = (
  data: IncomeData,
  handleClick: () => void
) => {
  if (!data) return
  const { finished } = data
  if (finished === 'N') {
    return {
      name: 'Baixa Manual',
      to: '#',
      icon: 'fa fa-university align-baseline',
      handleOnClick: handleClick
    }
  }
}
